import * as selectOfficeActions from "@app/login/select-office/select-office.actions";
import { Employee, Office } from "@app/models";
import { EmployeeOffices } from "@app/sidebar/profile/profile.component";
import { Action, createReducer, on } from "@ngrx/store";
import { Role, ROLE_SUPER_ADMIN } from "../utils/roles";
import * as userActions from "./user.actions";

export interface UserIds {
  eaEmployeeId: string;
  eaOfficeId: string;
}

export interface UserData extends UserIds {
  roles: string[];
}

export interface UserState {
  employee: Employee;
  office: Office;
  roles: Role[];
  clients: string[];
  selectedClient: string;
  authenticated: boolean;
  isViewingAsSomeoneElse: boolean;
  unauthorizedPath?: string;
  validatingToken: boolean;
  allEmployeesAndOffices: EmployeeOffices[];
  unmatchedSSOEmail: string;
  forbiddenLogin: boolean;
  detectedClientIdentifier: string;
  isLoading: boolean;
}

export const initialState: UserState = {
  employee: new Employee(),
  office: {},
  roles: [],
  clients: [],
  selectedClient: "",
  authenticated: false,
  isViewingAsSomeoneElse: false,
  unauthorizedPath: "",
  validatingToken: false,
  allEmployeesAndOffices: [],
  unmatchedSSOEmail: null,
  forbiddenLogin: false,
  detectedClientIdentifier: null,
  isLoading: false,
};

export function userReducer(state: UserState, action: Action): UserState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(
    userActions.loadSuccess,
    selectOfficeActions.loginOfficeSuccess,
    (state, { employee, office, roles }) => ({
      ...state,
      employee,
      office,
      roles: roles as Role[],
      authenticated: true,
    })
  ),
  on(userActions.setRoles, (state, { roles }) => {
    return { ...state, roles };
  }),
  on(
    userActions.setViewingAsSomeoneElse,
    (state, { isViewingAsSomeoneElse }) => ({
      ...state,
      isViewingAsSomeoneElse,
    })
  ),
  on(
    userActions.superAdminLoginRequest,
    userActions.clientLessSuperAdminLoginRequest,
    (state) => ({
      ...state,
      isLoading: true,
    })
  ),
  on(
    userActions.superAdminLoginFail,
    userActions.clientLessSuperAdminLoginFail,
    (state) => ({
      ...state,
      isLoading: false,
    })
  ),
  on(
    userActions.superAdminLoginSuccess,
    userActions.clientLessSuperAdminLoginSuccess,
    (state) => {
      const roles = state?.roles?.length > 0 ? state.roles : [];
      roles.push(ROLE_SUPER_ADMIN);

      return {
        ...state,
        authenticated: true,
        roles,
      };
    }
  ),
  on(userActions.unautorizedPathStored, (state, { unauthorizedPath }) => ({
    ...state,
    unauthorizedPath,
  })),
  on(userActions.clearUnauthorizedPath, (state) => ({
    ...state,
    unauthorizedPath: initialState.unauthorizedPath,
  })),
  on(userActions.unauthorized, (_state, { unauthorizedPath }) => ({
    ...initialState,
    unauthorizedPath,
  })),
  on(userActions.validateOauthTokenRequest, () => ({
    ...initialState,
    validatingToken: true,
  })),
  on(userActions.validateOauthTokenSuccess, () => ({
    ...initialState,
    validatingToken: false,
  })),
  on(
    userActions.validateOauthTokenFail,
    (_state, { unmatchedSSOEmail, forbiddenLogin }) => ({
      ...initialState,
      validatingToken: false,
      unmatchedSSOEmail,
      forbiddenLogin,
    })
  ),
  on(
    userActions.loadAllEmployeesAndOfficesSuccess,
    (state, { employeeOffices }) => ({
      ...state,
      allEmployeesAndOffices: employeeOffices,
    })
  ),
  on(userActions.logout, () => ({ ...initialState })),
  on(userActions.loadClientSuccess, (state, { clients }) => {
    const sortedClients = clients.slice().sort((a, b) => a.localeCompare(b));
    return {
      ...state,
      clients: sortedClients,
    };
  }),
  on(userActions.switchClientSuccess, (state, { clientName }) => ({
    ...state,
    selectedClient: clientName,
  })),
  on(userActions.detectClientSuccess, (state, { clientName }) => ({
    ...state,
    selectedClient: clientName,
  }))
);
