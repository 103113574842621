import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import {
  getIsBrandkeeperConfigured,
  getIsNewsletterConfigured,
  hasInternalService,
} from "@app/integrations/ngrx/integrations.reducer";
import { SubPage } from "@app/shared-features/mobile-navigation/ngrx/mobile-navigation.reducer";
import { getFeature, getSubPages } from "@app/shared/config/config.reducer";
import { SubPageConfig } from "@app/shared/config/models";
import { TAILORED_CAMPAIGNS } from "@app/shared/config/utils/features";
import {
  BRAND_KEEPER,
  MAIN_PLATFORM,
  NEWSLETTER,
  SOCIAL_MEDIA,
} from "@app/shared/config/utils/sub-pages";
import { CAMPAIGNS_CRM_MODULE } from "@app/shared/utils/crm-modules";
import { RouteAutoResolver } from "@app/shared/utils/route-auto-resolver";
import { select, Store } from "@ngrx/store";
import { BehaviorSubject, first, map, withLatestFrom } from "rxjs";

@Injectable()
export class CampaignsSubpageService {
  private _subPages$ = new BehaviorSubject([]);

  get subPages$() {
    return this._subPages$.asObservable();
  }

  constructor(
    private store: Store<AppState>,
    private routeAutoResolver: RouteAutoResolver
  ) {}

  loadSubPages(): void {
    this.store
      .pipe(
        select(getSubPages(CAMPAIGNS_CRM_MODULE)),
        first(),
        withLatestFrom(
          this.store.select(getIsNewsletterConfigured()),
          this.store.pipe(
            select(getFeature(TAILORED_CAMPAIGNS)),
            map((feature) => feature.enabled)
          ),
          this.store.select(getIsBrandkeeperConfigured()),
          this.store.pipe(select(hasInternalService("mainPlatformSSOService")))
        ),
        map(
          ([
            subPages,
            isNewsletterConfigured,
            hasTailoredCampaigns,
            isBrandkeeprConfigured,
            hasAccessToMainPlatform,
          ]) => {
            const items = subPages.filter((p) => {
              if (p.type === SOCIAL_MEDIA) {
                return p.enabled && hasTailoredCampaigns;
              } else if (p.type === NEWSLETTER) {
                return p.enabled && isNewsletterConfigured;
              } else if (p.type === BRAND_KEEPER) {
                return p.enabled && isBrandkeeprConfigured;
              } else {
                return p.enabled;
              }
            });

            if (hasAccessToMainPlatform) {
              items.push({
                type: MAIN_PLATFORM,
                enabled: true,
              } as SubPageConfig);
            }
            return items;
          }
        ),
        map((subPages: SubPageConfig[]) =>
          subPages.map((p) => this.getSubPage(p.type))
        )
      )
      .subscribe((pages) => {
        this._subPages$.next(pages);
        this.routeAutoResolver.autoResolve(pages);
      });
  }

  private getSubPage(type: string): SubPage {
    switch (type) {
      case NEWSLETTER: {
        return {
          label: "newsletter",
          routerLink: this.getRoute("newsletter"),
          icon: "paper-plane",
        };
      }
      case SOCIAL_MEDIA: {
        return {
          label: "social_media",
          routerLink: this.getRoute("social-media"),
          icon: "thumbs-up",
        };
      }
      case BRAND_KEEPER: {
        return {
          label: "marketing",
          routerLink: this.getRoute("media"),
          icon: "paintbrush-pencil",
        };
      }
      case MAIN_PLATFORM: {
        return {
          label: "marketing",
          routerLink: this.getRoute(MAIN_PLATFORM),
          icon: "paper-plane",
        };
      }
    }
    return {
      label: "?",
      routerLink: ["/crm"],
      icon: "fa-question",
    };
  }

  private getRoute(subRoute: string): any[] {
    return ["/crm", CAMPAIGNS_CRM_MODULE, subRoute];
  }
}
