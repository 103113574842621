import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { first } from "rxjs";
import { AppState } from "@app/app.reducer";
import { go } from "@app/core/ngrx/router/router.actions";
import { AuthService } from "@app/core/services/auth/auth.service";
import { LocalStorageService } from "@app/core/services/local-storage/local-storage.service";

const PAGES_MAPPING = {
  crm: "crm",
  focusAreas: "crm/settings/sales-areas",
};

@Component({
  selector: "app-external-redirect",
  templateUrl: "./external-redirect.component.html",
  styleUrls: ["./external-redirect.component.scss"],
})
export class ExternalRedirectComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private localService: LocalStorageService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.route.parent.queryParams.pipe(first()).subscribe((data) => {
      const { token, linkTarget } = data;
      const page = PAGES_MAPPING[linkTarget] ?? "crm";

      //#/crm/dashboard/todo-list
      this.localService.saveUserPreference(
        "redirect_after_login_url",
        "#/" + page
      );

      this.authService.setToken(token);

      if (this.authService.isTokenExpired()) {
        this.store.dispatch(go({ path: "login" }));
        return;
      }

      this.authService.loadUserData();

      const { eaEmployeeId, eaOfficeId } = this.authService.getIds();
      if (
        this.authService.hasRole("crm2 admins") &&
        (!eaEmployeeId || !eaOfficeId)
      ) {
        this.store.dispatch(go({ path: ["admin", "select-employee"] }));
        return;
      }

      return this.store.dispatch(go({ path: page }));
    });
  }
}
