import { WidgetSize } from "@app/widgets/widgets/widget/widget-size";

// region Statistics widgets
export const LEAD_RESULT = "lead_result";
export const SALES_MEETING_RESULT = "sales_meeting_result";
export const SALES_MEETING_BOOKED = "sales_meeting_booked";
export const SUMMARY = "summary";
export const NUMBER_OF_BROKERS = "number_of_brokers";
export const NPS = "nps";
export const NEW_RECRUITS = "new_recruits";
export const BUDGET = "budget";
export const REVENUE = "revenue";
export const SOLD_OBJECTS = "sold_objects";
export const OBJECT_STATUS = "object_status";
export const EMPLOYEES_PERFORMANCE = "employees_performance";
export const TAILORED_CAMPAIGN = "tailored_campaign";
export const ADFENIX_MARKETING_REPORTS = "adfenix_marketing_report";
export const ADFENIX_MARKETING_REPORTS_SUMMARY =
  "adfenix_marketing_report_summary";
export const KPI_COLLECTION = "kpi_collection";
export const TOP_LISTS = "top_lists";
export const AKTIV_NPS = "aktiv_nps";
// endregion

// region Dashboard widgets
export const CALENDAR = "calendar";
export const FREE_LEADS = "free_leads";
export const OVERDUE_TASKS = "overdue_tasks";
export const OUTGOING_MAIL = "outgoing_mails";
export const BROKER_TIMELINE = "broker_timeline";
export const MORE_BUSINESS_OPPORTUNITIES = "more_business_opportunities";
export const LATEST_OBJECTS = "latest_objects";
export const LATEST_OBJECTS_FOR_SALE = "latest_objects_for_sale";
export const LATEST_CONTACTS = "latest_contacts";
export const CURRENT_TASKS = "current_tasks";
export const TASKS = "tasks";
export const LEADS = "leads";
export const VALUE_MONITOR = "value_monitor";
export const ERA_INTRANET = "era_intranet";
export const SHORTCUTS = "shortcuts";
export const THIRD_PARTY_WIDGET = "third_party_widget";
// endregion

// region Contact widgets
export const CONTACT_DETAILS = "contact_details";
export const CONTACT_NEXT_STEP = "contact_next_step";
export const CONTACT_NOTES = "contact_notes";
export const CONTACT_OBJECTS = "contact_objects";
export const CONTACT_RESIDENCES = "contact_residences";
export const CONTACT_STATUS = "contact_status";
export const CONTACT_RELATIONS = "contact_relations";
export const COMPANY_DETAILS = "company_details";
// endregion

// region Object widgets
export const PROJECT_OVERVIEW = "project_overview";
export const PROJECT_OBJECT_LIST = "project_object_list";
export const OBJECT_OVERVIEW = "object_overview";
export const OBJECT_IMAGES = "object_images";
export const OBJECT_UPCOMING_SHOWINGS = "object_upcoming_showings";
export const OBJECT_PARTIES = "object_parties";
export const OBJECT_DESCRIPTION = "object_description";
export const OBJECT_HIGHEST_BID = "object_highest_bid";
export const RENTAL_OVERVIEW = "rental_overview";
export const OBJECT_NOTES = "object_notes";
export const OBJECT_BRANDKEEPER = "object_brandkeeper";
export const ADFENIX_PROPERTY_REPORTING = "adfenix_property_reporting";
// endregion

export const STRETCH_WIDGETS = [KPI_COLLECTION, SHORTCUTS];

export const LARGE_WIDGETS = [
  SUMMARY,
  OBJECT_STATUS,
  PROJECT_OBJECT_LIST,
  EMPLOYEES_PERFORMANCE,
  TOP_LISTS,
];

export const SMALL_WIDGETS = [
  CURRENT_TASKS,
  TASKS,
  LEADS,
  OVERDUE_TASKS,
  FREE_LEADS,
  NUMBER_OF_BROKERS,
  LEAD_RESULT,
  NPS,
  OUTGOING_MAIL,
  BROKER_TIMELINE,
  MORE_BUSINESS_OPPORTUNITIES,
  LATEST_CONTACTS,
  CONTACT_NEXT_STEP,
  CONTACT_OBJECTS,
  CONTACT_RESIDENCES,
  PROJECT_OVERVIEW,
  OBJECT_UPCOMING_SHOWINGS,
  OBJECT_OVERVIEW,
  OBJECT_PARTIES,
  OBJECT_DESCRIPTION,
  OBJECT_HIGHEST_BID,
  OBJECT_IMAGES,
  RENTAL_OVERVIEW,
  CONTACT_DETAILS,
  CONTACT_STATUS,
  CONTACT_RELATIONS,
  COMPANY_DETAILS,
  REVENUE,
  SOLD_OBJECTS,
  ADFENIX_PROPERTY_REPORTING,
  ADFENIX_MARKETING_REPORTS_SUMMARY,
  ERA_INTRANET,
  OBJECT_NOTES,
  AKTIV_NPS,
];

export const getWidgetPadding = (type: string): "default" | "none" => {
  if (type === OBJECT_IMAGES || type === SHORTCUTS) {
    return "none";
  } else {
    return "default";
  }
};

export const getWidgetSize = (type: string): WidgetSize => {
  if (STRETCH_WIDGETS.includes(type)) {
    return "stretch";
  }
  if (LARGE_WIDGETS.includes(type)) {
    return "large";
  }
  if (SMALL_WIDGETS.includes(type)) {
    return "small";
  }
  return "medium";
};

export const getWidgetShowMoreLink = (type: string, id?: string): string[] => {
  switch (type) {
    case LEAD_RESULT:
      return ["/crm", "statistics", "leads"];
    case SALES_MEETING_RESULT:
      return ["/crm", "statistics", "sales-meetings"];
    case SALES_MEETING_BOOKED:
      return ["/crm", "statistics", "sales-meetings"];
    case ADFENIX_MARKETING_REPORTS:
    case ADFENIX_MARKETING_REPORTS_SUMMARY:
      return ["/crm", "statistics", "marketing-reports"];
    case FREE_LEADS:
    case LEADS:
      return ["/crm", "lists", "leads"];
    case TASKS:
    case OVERDUE_TASKS:
      return ["/crm", "lists", "tasks"];
    case OUTGOING_MAIL:
      return ["/crm", "lists", "outgoing-messages"];
    case LATEST_OBJECTS:
    case LATEST_OBJECTS_FOR_SALE:
      return ["/crm", "objects", "overview"];
    case LATEST_CONTACTS:
      return ["/crm", "lists", "contacts"];
    case CONTACT_RESIDENCES:
    case CONTACT_OBJECTS:
      return ["/crm", "contacts", id, "residences"];
    case OBJECT_HIGHEST_BID:
      return ["/crm", "showings", id, "potential-buyers"];
    default:
      return null;
  }
};

export const getWidgetIcon = (type: string) => {
  switch (type) {
    case SUMMARY:
      return "chart-line";
    case NPS:
      return "smile";
    case LEAD_RESULT:
      return "street-view";
    case OBJECT_STATUS:
      return "list";
    case SALES_MEETING_BOOKED:
      return "briefcase";
    case SALES_MEETING_RESULT:
      return "briefcase";
    case BUDGET:
      return "street-view";
    case REVENUE:
      return "wallet";
    case SOLD_OBJECTS:
      return "house";
    case NUMBER_OF_BROKERS:
      return "user-friends";
    case CALENDAR:
      return "calendar";
    case FREE_LEADS:
    case LEADS:
      return "street-view";
    case OVERDUE_TASKS:
      return "check";
    case OUTGOING_MAIL:
      return "envelope";
    case BROKER_TIMELINE:
      return "clock";
    case MORE_BUSINESS_OPPORTUNITIES:
      return "sack-dollar";
    case LATEST_OBJECTS_FOR_SALE:
      return "funnel-dollar";
    case LATEST_OBJECTS:
      return "home";
    case LATEST_CONTACTS:
      return "user";
    case CURRENT_TASKS:
    case TASKS:
      return "pen";
    case TAILORED_CAMPAIGN:
      return "thumbs-up";
    case ERA_INTRANET:
      return "network-wired";
    case KPI_COLLECTION:
      return "chart-line";
    default:
      return "check";
  }
};

export const getWidgetLabel = (type: string) => {
  switch (type) {
    case "summary":
      return "summary";
    case "nps":
      return "nps";
    case "lead_result":
      return "leads";
    case "object_status":
      return "key_object_statuses";
    case "sales_meeting_result":
      return "result_of_sales_meetings";
    case "sales_meeting_booked":
      return "sales_meetings";
    case "budget":
      return "results_vs_budget";
    case "revenue":
      return "revenue_latest_365_days";
    case "sold_objects":
      return "sold_objects_latest_365_days";
    case "number_of_brokers":
      return "no_of_brokers";
    case "tailored_campaign":
      return "tailored_campaign";
    case KPI_COLLECTION:
      return "kpi";
    case "top_lists":
      return "top_lists";
    default:
      return type;
  }
};
