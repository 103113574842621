import { NpsEntity } from "@app/models/nps";
import { createAction, props } from "@ngrx/store";

export const fetchStatisticsRequest = createAction(
  "[Widget AKTIV NPS] FETCH_STATISTICS_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const fetchStatisticsSuccess = createAction(
  "[Widget AKTIV NPS] FETCH_STATISTICS_SUCCESS",
  props<{ nps: NpsEntity }>()
);

export const fetchStatisticsFail = createAction(
  "[Widget AKTIV NPS] FETCH_STATISTICS_FAIL"
);
