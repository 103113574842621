<section>
  <div class="login-fail">
    <ng-container *ngIf="forbiddenLogin$ | async; else unmatchedSSO">
      <div>
        <h1 translate>login_failed</h1>
        <h3>
          {{ "dont_have_access" | translate }}
        </h3>
      </div>
    </ng-container>
    <ng-template #unmatchedSSO>
      <div *ngIf="unmatchedSSOEmail$ | async as email; else ssoError">
        <h1 translate>login_failed</h1>
        <h3>
          {{ "unmatched_sso_email_message" | translate : { email: email } }}
        </h3>
      </div>
    </ng-template>
    <ng-template #ssoError>
      <h1 translate>sso_fail_message</h1>
    </ng-template>
    <ng-container
      *ngIf="(supportEnabled$ | async) && !(forbiddenLogin$ | async)"
    >
      <div *ngIf="supportMail$ | async" class="support__mail">
        <h2>{{ "email" | translate }}</h2>
        <a href="mailto:{{ supportMail$ | async }}">{{
          supportMail$ | async
        }}</a>
      </div>
      <div
        *ngIf="(supportTel$ | async) && (supportTelCountry$ | async)"
        class="support__tel"
      >
        <h2>{{ "support_phone" | translate }}</h2>
        <a href="tel:+{{ supportTel$ | async }}">
          {{
            "+" + (supportTel$ | async)
              | phoneFormat : (supportTelCountry$ | async)
          }}
        </a>
      </div>
    </ng-container>
  </div>
</section>
