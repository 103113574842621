import { Component, OnDestroy, OnInit } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as fromSalesMeeting from "@app/contacts/contact-sales-meetings/contact-sales-meetings.reducer";
import * as fromContact from "@app/contacts/contact.selectors";
import { hasIntegration } from "@app/integrations/ngrx/integrations.reducer";
import { Contact, Office, QObject } from "@app/models";
import { Municipality } from "@app/models/municipality";
import * as fromConfig from "@app/shared/config/config.reducer";
import { Feature } from "@app/shared/config/models";
import { ExternalProviderFeature } from "@app/shared/config/models/external-provider";
import * as features from "@app/shared/config/utils/features";
import { EXTERNAL_PROVIDER } from "@app/shared/config/utils/features";
import * as fromShared from "@app/shared/ngrx/shared.reducer";
import * as fromUser from "@app/shared/user";
import * as externalProviders from "@app/shared/utils/external-providers";
import { ObjectType } from "@app/shared/utils/q-object-types";
import { CREATE_IN_EXTERNAL_PROVIDER } from "@app/shared/utils/tab-types";
import * as externalProviderActions from "@app/sidebar/external-provider/ngrx/external-provider.actions";
import * as fromExternalProvider from "@app/sidebar/external-provider/ngrx/external-provider.reducer";
import { SidebarTab } from "@app/sidebar/models/sidebar-tab";
import { ConnectableTab } from "@app/sidebar/sidebar-connectable-tab";
import * as sidebarActions from "@app/sidebar/ngrx/sidebar.actions";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import {
  combineLatest,
  debounceTime,
  filter,
  first,
  map,
  Observable,
  Subject,
  take,
  takeUntil,
  withLatestFrom,
} from "rxjs";
import { OfficeService } from "@app/core/ngrx/entity-services/office.service";

@Component({
  selector: "app-create-in-external-provider",
  templateUrl: "./create-in-external-provider.component.html",
  styleUrls: [
    "../../../sidebar.component.common.scss",
    "./create-in-external-provider.component.scss",
    "../../../../sidebar/shared/sidebar-header/sidebar-header.component.scss",
  ],
})
export class CreateInExternalProviderComponent
  implements OnInit, OnDestroy, ConnectableTab
{
  tab$: Observable<SidebarTab>;
  proxy$ = new Subject<any>();
  tabType = CREATE_IN_EXTERNAL_PROVIDER;
  providerName$: Observable<string>;
  residence$: Observable<QObject>;
  residenceObjType$: Observable<string | number>;
  patching$: Observable<boolean>;
  eaOid$: Observable<string>;
  contact$: Observable<Contact>;
  officeId$: Observable<string>;
  eaOfficeId$: Observable<string>;
  office$: Observable<Office>;
  disableMunicipalitySelectBox$: Observable<boolean>;
  municipalities$: Observable<Municipality[]>;
  preselectedMunicipalityCode$: Observable<string>;
  preselectedMunicipalityId$: Observable<number>;
  unsubscribe$: Subject<void> = new Subject<void>();
  externalProviders = externalProviders;
  addressSuggesterFeature$: Observable<Feature>;
  addressValidationFeature$: Observable<Feature>;
  countryCode$: Observable<string>;
  hasObjectLinks$: Observable<boolean>;
  cityChangeNeeded$: Observable<boolean>;
  objectTypes$: Observable<ObjectType[]>;

  constructor(
    private store: Store<AppState>,
    private translateService: TranslateService,
    private officeService: OfficeService
  ) {}

  ngOnInit(): void {
    this.mapStateToProps();
    this.getResidence();
    this.getMunicipalities();
    this.connectTab();
  }

  mapStateToProps(): void {
    this.providerName$ = this.store.pipe(
      select(fromConfig.getFeature(EXTERNAL_PROVIDER)),
      map((feature: ExternalProviderFeature) => feature.name),
      filter((value) => !!value)
    );
    this.residence$ = this.store.pipe(
      select(fromExternalProvider.getSelectedResidence),
      filter((value) => !!value)
    );
    this.residenceObjType$ = this.store.pipe(
      select(fromExternalProvider.getSelectedResidenceObjType)
    );
    this.patching$ = this.store.pipe(select(fromExternalProvider.getPatching));
    this.eaOid$ = this.store
      .pipe(select(fromSalesMeeting.getSelectedSalesMeetingEaOid))
      .pipe(filter((value) => !!value));
    this.contact$ = this.store.pipe(select(fromContact.getContact));
    this.officeId$ = this.store.pipe(select(fromUser.getOfficeId));
    this.eaOfficeId$ = this.store.pipe(select(fromUser.getEaOfficeId));
    this.municipalities$ = this.store.pipe(
      select(fromExternalProvider.getMunicipalities),
      filter((municipalities) => municipalities.length > 0)
    );
    this.preselectedMunicipalityCode$ = this.store.pipe(
      select(fromExternalProvider.getPreselectedMunicipalityCode),
      filter((value) => !!value)
    );
    this.preselectedMunicipalityId$ = this.store.pipe(
      select(fromExternalProvider.getPreselectedMunicipalityId),
      filter((value) => !!value)
    );
    this.addressSuggesterFeature$ = this.store.pipe(
      select(fromConfig.getFeature(features.ADDRESS_SUGGESTER))
    );
    this.addressValidationFeature$ = this.store.pipe(
      select(fromConfig.getFeature(features.ADDRESS_VALIDATION))
    );
    this.countryCode$ = this.store.pipe(select(fromConfig.getCountry));
    this.hasObjectLinks$ = this.store.pipe(
      select(hasIntegration("OBJECT_LINKS"))
    );
    this.cityChangeNeeded$ = this.store.pipe(
      select(fromExternalProvider.getCityChangeNeeded)
    );
    this.office$ = this.eaOfficeId$.pipe(
      withLatestFrom(this.officeService.entityMap$),
      map(([eaOfficeId, offices]) => offices[eaOfficeId])
    );
    this.objectTypes$ = this.store.pipe(select(fromShared.getObjectTypes)).pipe(
      withLatestFrom(this.office$),
      map(([objectTypes, office]) => {
        if (!office || office?.countryCode?.toLowerCase() === "se") {
          return objectTypes;
        }

        return objectTypes.slice(1);
      }),
      map((objectTypes) =>
        objectTypes
          .slice()
          .map((item) => ({
            ...item,
            objectTypeName: this.translateService
              .instant(item.objectTypeAlias.toLowerCase())
              .toString(),
          }))
          .sort((a, b) => a.objectTypeName.localeCompare(b.objectTypeName))
      )
    );

    // To provide some customers like "SkandiaMäklarna International" using MSPECS ERP
    // who are not located in Sweden with the option to send property address information
    // without selecting a municipality, this variable has been defined to check this condition
    // and hide the municipality dropdown for these users.
    this.disableMunicipalitySelectBox$ = combineLatest([
      this.providerName$,
      this.office$,
    ]).pipe(
      map(
        ([providerName, office]) =>
          providerName.toLowerCase() === "mspecs" &&
          office?.countryCode.toLowerCase() !== "se"
      )
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  isLongText(label): boolean {
    return label && label.length > 17;
  }

  connectTab(): void {
    combineLatest([
      this.proxy$.pipe(take(1)),
      this.proxy$.pipe(debounceTime(100)),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([firstOne, current]) => {
        if (_.isEqual(firstOne, current)) {
          this.store.dispatch(
            sidebarActions.resetDirty({ tabType: this.tabType })
          );
        } else {
          this.store.dispatch(
            sidebarActions.markAsDirty({ tabType: this.tabType })
          );
        }
      });
  }

  getResidence(): void {
    this.eaOid$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((eaOid: string) =>
        this.store.dispatch(
          externalProviderActions.getResidenceRequest({ eaOid })
        )
      );
  }

  getMunicipalities(): void {
    combineLatest([
      this.store.pipe(select(fromConfig.getCountry)),
      this.residence$,
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([country, residence]) => {
        this.store.dispatch(
          externalProviderActions.getMunicipalitiesRequest({
            params: { country },
          })
        );
        if (residence && residence.zip) {
          this.store.dispatch(
            externalProviderActions.getPreselectedMunicipalityRequest({
              params: {
                country,
                zip: residence.zip,
              },
            })
          );
        }
      });
  }

  closeTab(): void {
    this.store.dispatch(sidebarActions.closeTab({ tabType: this.tabType }));
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  checkProperty(property: string): boolean {
    return !(property === "0" || property === "0.0" || !property);
  }

  getResidenceNoOfRooms(rooms: string): Observable<string> {
    return this.translateService.get("residential_rooms_details", {
      rooms: +rooms,
    });
  }

  getResidenceArea(area: string): Observable<string> {
    return this.translateService.get("residential_area_details", {
      surface_area: area,
    });
  }

  getCurrency(priceCode: string): string {
    return priceCode === "SKR" ? "SEK" : priceCode;
  }

  getTranslationKey(status: string, key: string): string {
    switch (status) {
      case "True":
        return `have_${key}`;
      case "False":
        return `no_${key}`;
      default:
        return `unknown_${key}`;
    }
  }

  handleSubmission(data: {
    contact?: Partial<Contact>;
    residence: Partial<QObject>;
    extraData?: Record<string, unknown>;
  }): void {
    combineLatest([
      this.eaOid$,
      this.contact$,
      this.officeId$,
      this.hasObjectLinks$,
      this.residence$,
    ])
      .pipe(first())
      .subscribe(([eaOid, contact, officeId, hasObjectLinks, residence]) => {
        if (data.contact) {
          this.store.dispatch(
            externalProviderActions.updateContactRequest({
              contact: data.contact,
              residence: { ...residence, ...data.residence, officeId },
              eaOid,
              contactId: contact.contactId,
              extraData: data.extraData ? data.extraData : null,
              hasObjectLinks:
                hasObjectLinks &&
                !residence.isExistingHome &&
                !residence.existingHome,
            })
          );
        } else {
          this.store.dispatch(
            externalProviderActions.updateResidenceRequest({
              residence: { ...residence, ...data.residence, officeId },
              eaOid,
              contactId: contact.contactId,
              extraData: data.extraData ? data.extraData : null,
              hasObjectLinks:
                hasObjectLinks &&
                !residence.isExistingHome &&
                !residence.existingHome,
            })
          );
        }
      });
  }
}
