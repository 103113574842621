import {
  createFeatureSelector,
  createSelector,
  select,
  Store,
} from "@ngrx/store";
import {
  Role,
  ROLE_ADMIN,
  ROLE_BROKER,
  ROLE_MANAGER,
} from "@app/shared/utils/roles";
import { Employee, Office } from "@app/models";
import { combineLatest, debounceTime, filter, map, withLatestFrom } from "rxjs";
import { AppState } from "@app/app.reducer";
import { getToggleState } from "@app/core/services/session/ngrx/session.reducer";
import * as outgoingMailActions from "@app/dashboard/outgoing-mails/outgoing-mail.actions";
import { FreeLeadsRequestObject } from "@app/dashboard/todo-list/free-leads-list/free-leads-list.actions";
import { RequestObject } from "@app/shared/modules/action-list/models";
import { UserIds, UserState } from "@app/shared/user/user.reducer";

const selectFeature = createFeatureSelector<UserState>("user");

export const getEmployee = createSelector(
  selectFeature,
  (state: UserState) => state.employee
);

export const getEaEmployeeId = createSelector(
  getEmployee,
  (employee: Employee) => employee.eaEmployeeId
);

export const getUnmatchedSSOEmail = createSelector(
  selectFeature,
  (state: UserState) => state.unmatchedSSOEmail
);

export const getForbiddenLogin = createSelector(
  selectFeature,
  (state: UserState) => state.forbiddenLogin
);

export const getEmployeeOffices = createSelector(
  getEmployee,
  (employee: Employee) => employee.offices
);

export const getEaOfficesIds = createSelector(
  getEmployee,
  (employee: Employee) => employee?.offices?.map((office) => office.eaOfficeId)
);

export const getAdminOrManagerEaOfficesIds = createSelector(
  getEmployee,
  (employee: Employee) => {
    const highLevelRoles: string[] = [ROLE_ADMIN, ROLE_MANAGER];
    const adminOrManagerOffices = employee?.offices?.filter(
      (office) =>
        highLevelRoles.includes(office.role) && office.status === "active"
    );

    return adminOrManagerOffices?.map((office) => office.eaOfficeId);
  }
);

export const getOffice = createSelector(
  selectFeature,
  (state: UserState) => state.office
);

export const getEaOfficeId = createSelector(
  getOffice,
  (office: Office) => office.eaOfficeId
);

export const getOfficeId = createSelector(
  getOffice,
  (office: Office) => office.officeId
);

export const getRoles = createSelector(
  selectFeature,
  (state: UserState) => state.roles
);

export const hasRole = (role: Role) =>
  createSelector(getRoles, (roles: Role[]) => {
    return roles?.length > 0 && roles?.some((r) => r === role);
  });

export const hasRoles = (roles: Role[]) =>
  createSelector(
    getRoles,
    (_roles: Role[]) =>
      _roles?.length > 0 && _roles?.some((r) => roles.includes(r))
  );

export const isManagerOrAdmin = createSelector(
  getRoles,
  (roles) =>
    roles?.length > 0 &&
    roles?.some((r) => r === ROLE_MANAGER || r === ROLE_ADMIN)
);

export const getHighestRole = createSelector(getRoles, (roles) => {
  if (roles?.length > 0) {
    return (
      roles?.find((r) => [ROLE_ADMIN, ROLE_MANAGER].includes(r)) || ROLE_BROKER
    );
  }

  return null;
});

export const getAuthenticated = createSelector(
  selectFeature,
  (state: UserState) => state.authenticated
);

export const getAllEmployeesAndOffices = createSelector(
  selectFeature,
  (state: UserState) => state.allEmployeesAndOffices
);

export const isViewingAsSomeoneElse = createSelector(
  selectFeature,
  (state: UserState) => state.isViewingAsSomeoneElse
);

export const getEmployeesManagerOffices = createSelector(
  getEmployee,
  (employee: Employee) =>
    employee?.offices?.filter((office) => office.role === ROLE_MANAGER)
);

export const getUserData = createSelector(
  selectFeature,
  (state: UserState) => ({
    eaEmployeeId: state.employee?.eaEmployeeId,
    eaOfficeId: state.office?.eaOfficeId,
    roles: state.roles,
  })
);

export const getClients = createSelector(
  selectFeature,
  (state: UserState) => state.clients
);

export const getSelectedClient = createSelector(
  selectFeature,
  (state: UserState) => state.selectedClient
);

export const authGuardObservable = (source, state$) => {
  return source.pipe(
    withLatestFrom(state$.pipe(select(getAuthenticated))),
    map(([data, authenticated]) => ({
      data,
      authenticated,
    })),
    filter(
      (resp: { data: UserIds; authenticated: boolean }) =>
        resp.authenticated === true
    ),
    map((resp: { data: UserIds; authenticated: boolean }) => resp.data)
  );
};

export const getOutgoingMailRequestObject = (state$: Store<AppState>) => {
  const requestObject$ = combineLatest([
    state$.pipe(select(hasRole(ROLE_MANAGER))),
    state$.pipe(select(hasRole(ROLE_ADMIN))),
    state$.pipe(select(getOffice)),
    state$.pipe(select(getToggleState("outgoingMail"))),
  ]).pipe(
    map(([isManager, isAdmin, office, showAll]) => {
      const result: outgoingMailActions.OutgoingMailsRequest = {};
      if (isAdmin && showAll) {
        return result;
      }
      if (isManager && showAll) {
        result.eaOfficeId = office.eaOfficeId;
      } else {
        result.eaEmployeeId = office.eaEmployeeId;
      }
      return result;
    }),
    debounceTime(1)
  );

  return authGuardObservable(requestObject$, state$);
};

export const getFreeLeadsRequestObject = (state$: Store<AppState>) => {
  // TODO Can maybe be moved to proxy
  const requestObject$ = combineLatest([
    state$.pipe(select(hasRole(ROLE_BROKER))),
    state$.pipe(select(getEaOfficeId)),
  ]).pipe(
    map(([isBroker, eaOfficeId]) => {
      const result: FreeLeadsRequestObject = {
        eaOfficeId: eaOfficeId,
        taskNotConnectedToEmployee: isBroker
          ? "excludeOfficeManagerExclusive"
          : "true",
      };

      return result;
    })
  );

  return authGuardObservable(requestObject$, state$);
};

export const getLatestActionsRequestObject = (state$: Store<AppState>) => {
  const src$ = combineLatest([
    state$.pipe(select(hasRole(ROLE_MANAGER))),
    state$.pipe(select(getOffice)),
    state$.pipe(select(getToggleState("latestActions"))),
  ]).pipe(
    map(([isManager, office, showAll]) => {
      if (isManager && showAll) {
        return { eaOfficeId: office.eaOfficeId } as RequestObject;
      }
      return { eaEmployeeId: office.eaEmployeeId } as RequestObject;
    })
  );
  return authGuardObservable(src$, state$);
};

export const getEaIds = (state$: Store<AppState>) => {
  const ids$ = combineLatest([
    state$.pipe(select(getEaEmployeeId)),
    state$.pipe(select(getEaOfficeId)),
  ]).pipe(
    map(
      ([eaEmployeeId, eaOfficeId]) => ({ eaEmployeeId, eaOfficeId } as UserIds)
    )
  );

  return authGuardObservable(ids$, state$);
};
