import { createFeatureSelector, createSelector } from "@ngrx/store";
import { GeometryCollection, Polygon } from "geojson";
import { SalesFocusAreasState } from "./state";
import { Employee } from "@app/models";

const selectFeature =
  createFeatureSelector<SalesFocusAreasState>("salesFocusAreas");

const getCoordinatesForGeometry = (
  geometry: GeometryCollection | Polygon
): { lat: number; lng: number }[] => {
  if (geometry.type === "Polygon") {
    return geometry.coordinates[0].map(([lng, lat]) => ({ lat, lng }));
  } else if (geometry.type === "GeometryCollection") {
    return geometry.geometries.flatMap(getCoordinatesForGeometry);
  }
  return [];
};

export const selectEntityType = createSelector(
  selectFeature,
  (state) => state.entityType
);

export const selectSalesFocusAreas = createSelector(
  selectFeature,
  (state) => state.areas
);

export const selectEntityIds = createSelector(selectFeature, (state) => ({
  agentIds: state.agentIds,
  officeIds: state.officeIds,
  entityType: state.entityType,
}));

export const selectHasModifiedArea = createSelector(
  selectFeature,
  (state) => state.areaBeingEdited !== null
);

export const selectIsLoading = createSelector(
  selectFeature,
  (state) => state.isLoading
);

export const getAllEmployees = createSelector(
  selectFeature,
  (state) => state.employees
);

export const getEmployeeDetail = (eaEmployeeId: string) =>
  createSelector(getAllEmployees, (employees: Employee[]) =>
    employees?.find((emp) => emp.eaEmployeeId === eaEmployeeId)
  );

export const getIsLoadingMarketShare = createSelector(
  selectFeature,
  (state) => state.isLoadingMarketShare
);

export const getIsLoadingOptimal = createSelector(
  selectFeature,
  (state) => state.isLoadingOptimal
);
