import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { AppState } from "@app/app.reducer";
import {
  LeadResultWidgetConfig,
  ObjectStatusWidgetConfig,
  SoldObjectsWidgetConfig,
  SummaryWidgetConfig,
  WidgetConfig,
} from "@app/shared/config/models/widgets-page-config";
import * as fromUser from "@app/shared/user";
import { Role, ROLE_ADMIN, ROLE_MANAGER } from "@app/shared/utils/roles";
import * as widgets from "@app/statistics/utils/supported-widgets";
import {
  getWidgetPadding,
  getWidgetShowMoreLink,
  getWidgetSize,
  OBJECT_STATUS,
  SUMMARY,
} from "@app/statistics/utils/supported-widgets";
import { select, Store } from "@ngrx/store";
import { combineLatest, filter, map, Observable } from "rxjs";
import { WidgetSize } from "@app/widgets/widgets/widget/widget-size";

@Component({
  selector: "app-widget-page",
  templateUrl: "./widget-page.component.html",
  styleUrls: ["./widget-page.component.scss"],
})
export class WidgetPageComponent implements OnInit, OnChanges {
  @Input() widgetConfig:
    | WidgetConfig[]
    | LeadResultWidgetConfig[]
    | SoldObjectsWidgetConfig[]
    | ObjectStatusWidgetConfig[] = [];
  @Input() eaEmployeeId: string;
  @Input() eaOfficeId: string;
  @Input() contactId?: string;
  @Input() eaOid?: string;

  // This will use the appropriate eaIds based on role rights for certain widgets,
  // instead of the inputs.
  @Input() useRoleEaIds = false;
  @Input() openLinkInNewTab = false;
  @Input() useDefaultSize = true;
  @Input() showHeader = true;
  @Input() widgetsSize: WidgetSize = null;

  widgets = widgets;
  isAdmin$: Observable<boolean>;
  isManager$: Observable<boolean>;
  role$: Observable<Role>;
  roleBasedEaEmployeeId$: Observable<string>;
  roleBasedEaOfficeId$: Observable<string>;

  returnTypes: string[] = [];
  objectStatuses: any[] = [];

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    //   Todo: Set default eaEmployeeId if not specified
    this.mapStateToProps();
  }

  ngOnChanges(changes): void {
    if (changes?.widgetConfig && !!this.widgetConfig) {
      this.setReturnTypes();
      this.setObjectStatuses();
    }
  }

  getSize(type: string) {
    return this.useDefaultSize ? getWidgetSize(type) : this.widgetsSize;
  }

  getPadding(type: string) {
    return getWidgetPadding(type);
  }

  getShowMoreLink(type: string) {
    let id = this.contactId;
    if (!!this.eaOid) {
      id = this.eaOid;
    }
    return getWidgetShowMoreLink(type, id);
  }

  private mapStateToProps() {
    this.isAdmin$ = this.store.pipe(select(fromUser.hasRole(ROLE_ADMIN)));
    this.isManager$ = this.store.pipe(select(fromUser.hasRole(ROLE_MANAGER)));
    this.role$ = combineLatest([
      this.store.pipe(select(fromUser.hasRole(ROLE_ADMIN))),
      this.store.pipe(select(fromUser.hasRole(ROLE_MANAGER))),
    ]).pipe(
      map(([isAdmin, isManager]) => {
        if (isAdmin) {
          return "admin";
        } else if (isManager) {
          return "manager";
        } else {
          return "broker";
        }
      })
    );

    this.roleBasedEaEmployeeId$ = this.role$.pipe(
      filter((val) => !!val),
      map((role) => {
        if (!this.useRoleEaIds) {
          return this.eaEmployeeId;
        } else if (role === "admin" || role === "manager") {
          return null;
        } else {
          return this.eaEmployeeId;
        }
      })
    );

    this.roleBasedEaOfficeId$ = this.role$.pipe(
      map((role) => {
        if (!this.useRoleEaIds) {
          return this.eaOfficeId;
        } else if (role === "admin") {
          return null;
        } else {
          return this.eaOfficeId;
        }
      })
    );
  }

  private setObjectStatuses() {
    const widget: ObjectStatusWidgetConfig = this.widgetConfig.find(
      (widget) => widget.type === OBJECT_STATUS
    );
    if (!!widget && widget?.objectStatuses.length > 0) {
      this.objectStatuses = widget?.objectStatuses;
    }
  }

  private setReturnTypes() {
    // @ts-ignore
    const widget: SummaryWidgetConfig = this.widgetConfig.find(
      (widget) => widget.type === SUMMARY
    );
    if (!!widget) {
      // @ts-ignore
      this.returnTypes = Object.keys(widget?.returnTypes)
        .filter((key) => !!widget.returnTypes[key].enabled)
        .sort(
          (a, b) =>
            widget.returnTypes[a].displayOrder -
            widget.returnTypes[b].displayOrder
        );
    }
  }
}
