import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppConfigService } from "@app/app-config.service";
import { AppState } from "@app/app.reducer";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { AuthService } from "@app/core/services/auth/auth.service";
import * as userActions from "@app/shared/user/user.actions";
import { FORBIDDEN, UNAUTHORIZED } from "@app/shared/utils/http-status-codes";
import { Store } from "@ngrx/store";
import { catchError, Observable, of, throwError } from "rxjs";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";

@Injectable()
export class ApiService {
  private headers;
  private extraHeaders;

  constructor(
    private authHttp: HttpClient,
    private authService: AuthService,
    private store: Store<AppState>,
    private appConfig: AppConfigService
  ) {
    this.headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    this.extraHeaders = new HttpHeaders({});
  }

  get(
    resource: string,
    searchParams: any = {},
    type = "api",
    checkIfAuthenticated = true
  ) {
    const params = this.buildURLSearchParameters(this.trimBody(searchParams));
    if (
      !checkIfAuthenticated ||
      type === "public" ||
      !!this.authService.isAuthenticated()
    ) {
      return this.authHttp
        .get(`${this.appConfig.config.apiUrl}${type}/${resource}`, {
          headers: this.headers,
          params: params,
        })
        .pipe(catchError((error) => throwError(() => this.onError(error))));
    } else {
      this.logout();
      return throwError(() => new Error("Not authenticated"));
    }
  }

  getWithoutErrorHandling(
    resource: string,
    searchParams: any = {},
    type = "api"
  ) {
    const params = this.buildURLSearchParameters(this.trimBody(searchParams));

    return this.authHttp
      .get(`${this.appConfig.config.apiUrl}${type}/${resource}`, {
        headers: this.headers,
        params: params,
      })
      .pipe(catchError((error) => throwError(() => error)));
  }

  getWithoutJson(resource: string, searchParams: any = {}, type = "api") {
    const params = this.buildURLSearchParameters(this.trimBody(searchParams));

    return this.authHttp
      .get(`${this.appConfig.config.apiUrl}${type}/${resource}`, {
        headers: this.headers,
        responseType: "text",
        params: params,
      })
      .pipe(catchError((error) => throwError(() => this.onError(error))));
  }

  post<T = any>(resource: string, body: any = {}, type = "api") {
    return this.authHttp.post<T>(
      `${this.appConfig.config.apiUrl}${type}/${resource}`,
      this.trimBody(body),
      {
        headers: this.extraHeaders,
      }
    );
  }

  postWithoutResponse(resource: string, body: any = {}, type = "api") {
    return this.authHttp
      .post(
        `${this.appConfig.config.apiUrl}${type}/${resource}`,
        this.trimBody(body),
        {
          responseType: "text",
          headers: this.extraHeaders,
        }
      )
      .pipe(catchError((error) => throwError(() => this.onError(error))));
  }

  postWithBlobType(resource: string, body: any = {}, type = "api") {
    return this.authHttp
      .post(
        `${this.appConfig.config.apiUrl}${type}/${resource}`,
        this.trimBody(body),
        {
          responseType: "blob",
          headers: this.extraHeaders,
        }
      )
      .pipe(catchError((error) => throwError(() => this.onError(error))));
  }

  postWithFormData(resource: string, body: FormData, type = "api") {
    return this.authHttp
      .post(`${this.appConfig.config.apiUrl}${type}/${resource}`, body, {
        headers: this.extraHeaders,
      })
      .pipe(catchError((error) => throwError(() => this.onError(error))));
  }

  patch(resource: string, body: any = {}, type = "api") {
    return this.authHttp
      .patch(
        `${this.appConfig.config.apiUrl}${type}/${resource}`,
        this.trimBody(body),
        { headers: this.extraHeaders }
      )
      .pipe(catchError((error) => throwError(() => this.onError(error))));
  }

  patchWithoutResponse(resource: string, body: any = {}, type = "api") {
    return this.authHttp
      .patch(
        `${this.appConfig.config.apiUrl}${type}/${resource}`,
        this.trimBody(body),
        {
          responseType: "text",
          headers: this.extraHeaders,
        }
      )
      .pipe(catchError((error) => throwError(() => this.onError(error))));
  }

  put(resource: string, body: any = {}, type = "api") {
    return this.authHttp
      .put(
        `${this.appConfig.config.apiUrl}${type}/${resource}`,
        this.trimBody(body),
        { headers: this.extraHeaders }
      )
      .pipe(catchError((error) => throwError(() => this.onError(error))));
  }

  delete(resource: string, searchParams: any = {}, type = "api") {
    const params = this.buildURLSearchParameters(this.trimBody(searchParams));
    return this.authHttp
      .delete(`${this.appConfig.config.apiUrl}${type}/${resource}`, {
        params,
        headers: this.headers,
      })
      .pipe(catchError((error) => throwError(() => this.onError(error))));
  }

  onError(error: { status: number }) {
    if (error.status === UNAUTHORIZED) {
      this.store.dispatch(userActions.logout());
    } else if (error.status === FORBIDDEN) {
      this.store.dispatch(toastActions.danger({ message: "dont_have_access" }));
    }

    return error;
  }

  buildURLSearchParameters(filters: any): HttpParams {
    let params = new HttpParams();
    Object.keys(filters).forEach((filter) => {
      params = params.append(filter, filters[filter]);
    });
    return params;
  }

  trimBody(body: any): any {
    const trimmedBody: { [k: string]: any } = {};
    Object.keys(body).forEach((param) => {
      if (body[param] !== null) {
        trimmedBody[param] = body[param];
      }
    });
    return trimmedBody;
  }

  logout(): Observable<any> {
    this.store.dispatch(userActions.logout());
    this.store.dispatch(RouterActions.go({ path: "/login" }));
    return of(null);
  }
}

export enum Categories {
  AdminConfiguration = "admin/configuration",
  Integrations = "integrations",
  Auto = "auto",
  Api = "api",
}

export const hardTrimBody = (body: any): any => {
  const trimmedBody: { [k: string]: any } = {};
  Object.keys(body).forEach((param) => {
    if (
      body[param] === false ||
      (body[param] && !Array.isArray(body[param])) ||
      (Array.isArray(body[param]) && body[param].length > 0)
    ) {
      trimmedBody[param] = body[param];
    }
  });
  return trimmedBody;
};

export const hardTrimBodyDeeply = (
  obj: Record<string, any>
): Record<string, any> => {
  const result: Record<string, any> = {};

  for (const key in obj) {
    if (!obj[key] || obj[key]?.length === 0) {
      continue; // Skip null values
    }

    if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
      const cleanedNestedObj = hardTrimBodyDeeply(obj[key]);
      if (Object.keys(cleanedNestedObj).length > 0) {
        result[key] = cleanedNestedObj;
      }
    } else {
      result[key] = obj[key];
    }
  }

  return result;
};
