import { Action, createReducer, on } from "@ngrx/store";
import { SalesFocusAreasState } from "./state";
import * as actions from "./actions";

const initialState: SalesFocusAreasState = {
  areas: [],
  entityType: null,
  agentIds: null,
  officeIds: null,
  areaBeingEdited: null,
  isLoading: false,
  isLoadingMarketShare: false,
  isLoadingOptimal: false,
  employees: [],
};

const reducer = createReducer(
  initialState,
  on(
    actions.fetchSalesFocusAreas,
    (state, { officeIds, agentIds, entityType }) => ({
      ...state,
      officeIds,
      agentIds,
      entityType,
      isLoading: true,
    })
  ),
  on(
    actions.fetchSalesFocusAreasSuccess,
    actions.searchSalesFocusAreasSuccess,
    (state, { areas }) => ({
      ...state,
      areas,
      isLoading: false,
    })
  ),
  on(actions.removeSalesFocusArea, (state, { id }) => ({
    ...state,
    areas: state.areas.filter((area) => area.id !== id),
  })),
  on(
    actions.saveSalesFocusAreaSuccess,
    actions.createSalesFocusAreaSuccess,
    (state, { area }) => ({
      ...state,
      areaBeingEdited: null,
      areas: [...state.areas, area],
    })
  ),
  on(actions.resetModifiedArea, (state) => ({
    ...state,
    areas: state.areas.map((area) => ({
      ...area,
      coordinates:
        area.id === state.areaBeingEdited?.id
          ? state.areaBeingEdited.originalCoordinates
          : area.coordinates,
    })),
    areaBeingEdited: null,
  })),
  on(actions.renameSalesFocusAreaSuccess, (state, { area }) => {
    return {
      ...state,
      areas: state.areas.map((oldArea) => {
        if (oldArea.id === area.id) {
          return {
            ...oldArea,
            ...area,
          };
        }
        return oldArea;
      }),
    };
  }),
  on(actions.updateSalesFocusArea, (state, { id, coordinates }) => {
    const originalCoordinates = state.areas.find(
      (area) => area.id === id
    ).coordinates;

    return {
      ...state,
      areaBeingEdited: {
        id,
        originalCoordinates: state.areaBeingEdited
          ? state.areaBeingEdited.originalCoordinates
          : originalCoordinates,
      },
      areas: state.areas.map((area) => {
        if (area.id === id) {
          return {
            ...area,
            coordinates,
          };
        }
        return area;
      }),
    };
  }),
  on(actions.optimalNumbersInFocusAreaRequest, (state) => {
    return {
      ...state,
      isLoadingOptimal: true,
    };
  }),
  on(
    actions.optimalNumbersInFocusAreaSuccess,
    (state, { id, numberOfContacts }) => {
      const areaIndex = state.areas.findIndex((area) => area.id === id);
      const updatedArea = {
        ...state.areas[areaIndex],
        houseHolds: numberOfContacts,
      };

      const updatedAreaList = [...state.areas];
      updatedAreaList[areaIndex] = updatedArea;

      return {
        ...state,
        areas: updatedAreaList,
        isLoadingOptimal: false,
      };
    }
  ),
  on(actions.optimalNumbersInFocusAreaFail, (state, { id }) => {
    const areaIndex = state.areas.findIndex((area) => area.id === id);
    const updatedArea = {
      ...state.areas[areaIndex],
      houseHolds: 0,
    };

    const updatedAreaList = [...state.areas];
    updatedAreaList[areaIndex] = updatedArea;

    return {
      ...state,
      areas: updatedAreaList,
      isLoadingOptimal: false,
    };
  }),
  on(actions.fetchFocusAreaMarketShareRequest, (state) => {
    return {
      ...state,
      isLoadingMarketShare: true,
    };
  }),
  on(
    actions.fetchFocusAreaMarketShareSuccess,
    (state, { id, areaShare, areaSales }) => {
      const areaIndex = state.areas.findIndex((area) => area.id === id);
      const updatedArea = {
        ...state.areas[areaIndex],
        areaShare,
        areaSales,
      };

      const updatedAreaList = [...state.areas];
      updatedAreaList[areaIndex] = updatedArea;

      return {
        ...state,
        areas: updatedAreaList,
        isLoadingMarketShare: false,
      };
    }
  ),
  on(actions.fetchFocusAreaMarketShareFail, (state, { id }) => {
    const areaIndex = state.areas.findIndex((area) => area.id === id);
    const updatedArea = {
      ...state.areas[areaIndex],
      areaShare: 0,
      areaSales: 0,
    };

    const updatedAreaList = [...state.areas];
    updatedAreaList[areaIndex] = updatedArea;

    return {
      ...state,
      areas: updatedAreaList,
      isLoadingMarketShare: false,
    };
  }),
  on(actions.fetchAllEmployeesSuccess, (state, { employees }) => {
    return {
      ...state,
      employees: employees,
    };
  }),
  on(actions.fetchAllEmployeesFail, (state) => {
    return {
      ...state,
      employees: [],
    };
  })
);

export function salesFocusAreasReducer(
  state: SalesFocusAreasState,
  action: Action
) {
  return reducer(state, action);
}
