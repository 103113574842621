<ng-container *ngIf="property?.eaOid">
  <div class="quick-actions">
    <ng-container *ngIf="showGuestbook$ | async">
      <div class="action-item">
        <a
          [routerLink]="[
            '/crm',
            {
              outlets: {
                primary: ['showings', property.eaOid, 'register'],
                sidebar: null
              }
            }
          ]"
          [matTooltip]="'open_register_view' | translate"
          target="_blank"
          matTooltipClass="q-tooltip"
          rel="noopener noreferrer"
          class="btn btn-secondary btn-secondary--ghost btn-circle object-button"
        >
          <app-icon size="x-small" name="book-open"></app-icon>
        </a>
      </div>
    </ng-container>
    <div class="action-item">
      <a
        (click)="prefillFilters()"
        [matTooltip]="'quick_search' | translate"
        target="_blank"
        matTooltipClass="q-tooltip"
        class="btn btn-secondary btn-secondary--ghost btn-circle object-button"
      >
        <app-icon size="x-small" name="search-plus"></app-icon>
      </a>
    </div>
    <ng-container *ngIf="!property.isExistingHome">
      <div class="action-item" *ngIf="completeUrl$ | async as url">
        <a
          [href]="url"
          [matTooltip]="'view_object_outside_quedro_crm' | translate"
          target="_blank"
          matTooltipClass="q-tooltip"
          rel="noopener noreferrer"
          class="btn btn-secondary btn-secondary--ghost btn-circle object-button"
        >
          <app-icon size="x-small" name="share"></app-icon>
        </a>
      </div>
      <div class="action-item" *ngIf="showCopyObject$ | async">
        <a
          (click)="handleCopyObject($event)"
          [matTooltip]="'copy' | translate"
          matTooltipClass="q-tooltip"
          class="btn btn-secondary btn-secondary--ghost btn-circle object-button"
        >
          <app-icon size="x-small" name="copy"></app-icon>
        </a>
      </div>
      <div class="action-item" *ngIf="subListEnable$ | async">
        <app-actions-menu
          [highlight]="true"
          size="x-small"
          dropdownAlignment="RIGHT"
        >
          <ng-container buttons>
            <li *ngIf="showCreateShowing$ | async">
              <app-q-button
                [label]="'create_showing'"
                [icon]="'users'"
                [fullWidth]="true"
                (click)="createShowing($event, property.eaOid)"
              ></app-q-button>
            </li>
            <li
              *ngIf="!!(hasObjectLinks$ | async) && !!(isKiviCustomer$ | async)"
            >
              <app-q-button
                label="open_in_kivi"
                icon="external-link"
                [fullWidth]="true"
                (click)="openMetaDataLink()"
              ></app-q-button>
            </li>
            <ng-container
              *ngIf="(isSuperAdmin$ | async) && (hasDealDebugging$ | async)"
            >
              <li>
                <app-q-button
                  buttonType="save"
                  icon="bug"
                  label="debugger_object_remigrate"
                  [fullWidth]="true"
                  (click)="handleDebuggerObject($event, 'remigrate')"
                >
                </app-q-button>
              </li>
              <li>
                <app-q-button
                  buttonType="save"
                  icon="bug"
                  label="debugger_object_conversion"
                  [fullWidth]="true"
                  (click)="handleDebuggerObject($event, 'conversion')"
                >
                </app-q-button>
              </li>
            </ng-container>
            <ng-container *ngIf="propertyTriggerEnabled$ | async">
              <li>
                <app-q-button
                  buttonType="save"
                  icon="ballot-check"
                  label="seller_choice"
                  [fullWidth]="true"
                  (click)="handleSellerChoiceTrigger($event)"
                >
                </app-q-button>
              </li>
            </ng-container>
          </ng-container>
        </app-actions-menu>
      </div>
      <div class="action-item" *ngIf="canCreateSeller$ | async">
        <app-q-button
          buttonType="save"
          label="add_seller"
          icon="person-circle-plus"
          (click)="openSellerOrBuyerSidebar($event, 'SELLER')"
        >
        </app-q-button>
      </div>
      <div *ngIf="canCreateBuyer$ | async" class="action-item">
        <app-q-button
          buttonType="save"
          label="add_buyer"
          icon="user-plus"
          (click)="openSellerOrBuyerSidebar($event, 'BUYER')"
        >
        </app-q-button>
      </div>
      <div *ngIf="mainPlatformSSOEnabled$ | async" class="action-item">
        <app-q-button
          buttonType="save"
          label="marketing"
          icon="paper-plane"
          (click)="openMainPlatform($event)"
        >
        </app-q-button>
      </div>
    </ng-container>
  </div>
</ng-container>
