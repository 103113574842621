import * as widgets from "@app/statistics/utils/supported-widgets";
import * as _ from "lodash";

export const defaultWidgetVisibility: WidgetVisibilityConfig = {
  broker: {
    displayOrder: 1,
    visible: false,
    defaultQuickFilter: "currentMonth",
  },
  manager: {
    displayOrder: 1,
    visible: false,
    defaultQuickFilter: "currentMonth",
  },
  admin: {
    displayOrder: 1,
    visible: false,
    defaultQuickFilter: "currentMonth",
  },
};

export const getWidgetVisibility = (order: number): WidgetVisibilityConfig => {
  return {
    broker: {
      displayOrder: order,
      visible: true,
      defaultQuickFilter: "currentMonth",
    },
    manager: {
      displayOrder: order,
      visible: true,
      defaultQuickFilter: "currentMonth",
    },
    admin: {
      displayOrder: order,
      visible: true,
      defaultQuickFilter: "currentMonth",
    },
  };
};

export interface WidgetDisplayOptions {
  visible: boolean;
  displayOrder: number;
  defaultQuickFilter: "yesterday" | "today" | "currentMonth" | "currentYear";
}

export interface WidgetVisibilityConfig {
  broker: WidgetDisplayOptions;
  manager: WidgetDisplayOptions;
  admin: WidgetDisplayOptions;
}

export interface WidgetConfig {
  type: string;
  enabled: boolean;
  visibility: WidgetVisibilityConfig;
}

export interface WidgetConfigWithHeader extends WidgetConfig {
  showHeader: boolean;
}

export interface LeadResultWidgetConfig extends WidgetConfig {
  taskTypes: string[];
}

interface EnabledDisplayOrderItem {
  enabled: boolean;
  displayOrder: number;
}

export interface ObjectStatusWidgetConfig extends WidgetConfig {
  objectStatuses?: { display: string; value: number }[];
}

export interface EmployeePerformanceWidgetConfig extends WidgetConfig {
  compareTo: "budget" | "lastYear";
  returnTypes: string[];
}

export interface SoldObjectsWidgetConfig extends WidgetConfig {
  returnType: string;
}

export interface SummaryWidgetConfig extends WidgetConfig {
  returnTypes: {
    turnover: EnabledDisplayOrderItem;
    numberOfSoldObjects: EnabledDisplayOrderItem;
    totalSoldPrice: EnabledDisplayOrderItem;
    averageCommission: EnabledDisplayOrderItem;
    averageCommissionPercentage: EnabledDisplayOrderItem;
    numberOfSalesContract: EnabledDisplayOrderItem;
    numberOfContractToSellHouses: EnabledDisplayOrderItem;
    numberOfSalesMeetings: EnabledDisplayOrderItem;
    NPS: EnabledDisplayOrderItem;
    NPSAverage: EnabledDisplayOrderItem;
    numberOfNPSReplays: EnabledDisplayOrderItem;
    totalNPSValue: EnabledDisplayOrderItem;
    numberOfSentTips: EnabledDisplayOrderItem;
    bankTips: EnabledDisplayOrderItem;
  };
}

export interface WidgetsConfig {
  lead_result?: LeadResultWidgetConfig;
  sales_meeting_result?: WidgetConfig;
  sales_meeting_booked?: WidgetConfig;
  summary?: SummaryWidgetConfig;
  top_lists?: WidgetConfig;
  aktiv_nps?: WidgetConfig;
  number_of_brokers?: WidgetConfig;
  nps?: WidgetConfig;
  new_recruits?: WidgetConfig;
  revenue?: WidgetConfig;
  sold_objects?: SoldObjectsWidgetConfig;
  budget?: WidgetConfig;
  object_status?: ObjectStatusWidgetConfig;
  calendar?: WidgetConfig;
  free_leads?: WidgetConfig;
  leads?: WidgetConfig;
  overdue_tasks?: WidgetConfig;
  outgoing_mails?: WidgetConfig;
  broker_timeline?: WidgetConfig;
  more_business_opportunities?: WidgetConfig;
  latest_objects?: WidgetConfig;
  latest_objects_for_sale?: WidgetConfig;
  latest_contacts?: WidgetConfig;
  current_tasks?: WidgetConfig;
  tasks?: WidgetConfig;
  contact_details?: WidgetConfig;
  contact_next_step?: WidgetConfig;
  contact_notes?: WidgetConfig;
  contact_objects?: WidgetConfig;
  contact_residences?: WidgetConfig;
  project_overview?: WidgetConfig;
  employees_performance?: EmployeePerformanceWidgetConfig;
  tailored_campaign?: WidgetConfig;
  adfenix_marketing_reports?: WidgetConfig;
  adfenix_marketing_reports_summary?: WidgetConfig;
  era_intranet?: WidgetConfig;
  kpi_collection?: WidgetConfigWithHeader;
  shortcuts?: WidgetConfigWithHeader;
  third_party_widget?: WidgetConfigWithHeader;
}

export const contactWidgetsConfigDefaults: WidgetsConfig = {
  contact_details: {
    type: widgets.CONTACT_DETAILS,
    enabled: true,
    visibility: defaultWidgetVisibility,
  },
  contact_next_step: {
    type: widgets.CONTACT_NEXT_STEP,
    enabled: true,
    visibility: defaultWidgetVisibility,
  },
  contact_notes: {
    type: widgets.CONTACT_NOTES,
    enabled: true,
    visibility: defaultWidgetVisibility,
  },
  contact_objects: {
    type: widgets.CONTACT_OBJECTS,
    enabled: true,
    visibility: defaultWidgetVisibility,
  },
  contact_residences: {
    type: widgets.CONTACT_RESIDENCES,
    enabled: true,
    visibility: defaultWidgetVisibility,
  },
};

export const widgetsConfigDefaults: WidgetsConfig = {
  lead_result: {
    type: widgets.LEAD_RESULT,
    enabled: false,
    visibility: defaultWidgetVisibility,
    taskTypes: [],
  },
  sales_meeting_result: {
    type: widgets.SALES_MEETING_RESULT,
    enabled: false,
    visibility: defaultWidgetVisibility,
  },
  sales_meeting_booked: {
    type: widgets.SALES_MEETING_BOOKED,
    enabled: false,
    visibility: defaultWidgetVisibility,
  },
  adfenix_marketing_reports: {
    type: widgets.ADFENIX_MARKETING_REPORTS,
    enabled: false,
    visibility: defaultWidgetVisibility,
  },
  adfenix_marketing_reports_summary: {
    type: widgets.ADFENIX_MARKETING_REPORTS_SUMMARY,
    enabled: false,
    visibility: defaultWidgetVisibility,
  },
  summary: {
    type: widgets.SUMMARY,
    enabled: false,
    visibility: defaultWidgetVisibility,
    returnTypes: {
      turnover: {
        enabled: true,
        displayOrder: 1,
      },
      numberOfSoldObjects: {
        enabled: true,
        displayOrder: 2,
      },
      totalSoldPrice: {
        enabled: false,
        displayOrder: 10,
      },
      averageCommission: {
        enabled: false,
        displayOrder: 10,
      },
      averageCommissionPercentage: {
        enabled: false,
        displayOrder: 10,
      },
      numberOfSalesContract: {
        enabled: true,
        displayOrder: 4,
      },
      numberOfContractToSellHouses: {
        enabled: false,
        displayOrder: 10,
      },
      numberOfSalesMeetings: {
        enabled: true,
        displayOrder: 3,
      },
      NPS: {
        enabled: false,
        displayOrder: 10,
      },
      NPSAverage: {
        enabled: false,
        displayOrder: 10,
      },
      numberOfNPSReplays: {
        enabled: false,
        displayOrder: 10,
      },
      totalNPSValue: {
        enabled: false,
        displayOrder: 10,
      },
      numberOfSentTips: {
        enabled: false,
        displayOrder: 10,
      },
      bankTips: {
        enabled: false,
        displayOrder: 10,
      },
    },
  },
  top_lists: {
    type: widgets.TOP_LISTS,
    enabled: false,
    visibility: defaultWidgetVisibility,
  },
  aktiv_nps: {
    type: widgets.AKTIV_NPS,
    enabled: false,
    visibility: defaultWidgetVisibility,
  },
  number_of_brokers: {
    type: widgets.NUMBER_OF_BROKERS,
    enabled: false,
    visibility: defaultWidgetVisibility,
  },
  nps: {
    type: widgets.NPS,
    enabled: false,
    visibility: defaultWidgetVisibility,
  },
  new_recruits: {
    type: widgets.NEW_RECRUITS,
    enabled: false,
    visibility: defaultWidgetVisibility,
  },
  revenue: {
    type: widgets.REVENUE,
    enabled: false,
    visibility: defaultWidgetVisibility,
  },
  sold_objects: {
    type: widgets.SOLD_OBJECTS,
    enabled: false,
    visibility: defaultWidgetVisibility,
    returnType: "numberOfSoldObjects",
  },
  object_status: {
    type: widgets.OBJECT_STATUS,
    enabled: false,
    visibility: defaultWidgetVisibility,
    objectStatuses: [],
  },
  budget: {
    type: widgets.BUDGET,
    enabled: false,
    visibility: defaultWidgetVisibility,
  },
  calendar: {
    type: widgets.CALENDAR,
    enabled: false,
    visibility: defaultWidgetVisibility,
  },
  free_leads: {
    type: widgets.FREE_LEADS,
    enabled: false,
    visibility: defaultWidgetVisibility,
  },
  leads: {
    type: widgets.LEADS,
    enabled: false,
    visibility: defaultWidgetVisibility,
  },
  overdue_tasks: {
    type: widgets.OVERDUE_TASKS,
    enabled: false,
    visibility: defaultWidgetVisibility,
  },
  outgoing_mails: {
    type: widgets.OUTGOING_MAIL,
    enabled: false,
    visibility: defaultWidgetVisibility,
  },
  broker_timeline: {
    type: widgets.BROKER_TIMELINE,
    enabled: false,
    visibility: defaultWidgetVisibility,
  },
  more_business_opportunities: {
    type: widgets.MORE_BUSINESS_OPPORTUNITIES,
    enabled: false,
    visibility: defaultWidgetVisibility,
  },
  latest_objects: {
    type: widgets.LATEST_OBJECTS,
    enabled: false,
    visibility: defaultWidgetVisibility,
  },
  latest_objects_for_sale: {
    type: widgets.LATEST_OBJECTS_FOR_SALE,
    enabled: false,
    visibility: defaultWidgetVisibility,
  },
  latest_contacts: {
    type: widgets.LATEST_CONTACTS,
    enabled: false,
    visibility: defaultWidgetVisibility,
  },
  current_tasks: {
    type: widgets.CURRENT_TASKS,
    enabled: false,
    visibility: defaultWidgetVisibility,
  },
  tasks: {
    type: widgets.TASKS,
    enabled: false,
    visibility: defaultWidgetVisibility,
  },
  employees_performance: {
    type: widgets.EMPLOYEES_PERFORMANCE,
    enabled: false,
    visibility: defaultWidgetVisibility,
    compareTo: "budget",
    returnTypes: [],
  },
  tailored_campaign: {
    type: widgets.TAILORED_CAMPAIGN,
    enabled: false,
    visibility: defaultWidgetVisibility,
  },
  era_intranet: {
    type: widgets.ERA_INTRANET,
    enabled: false,
    visibility: defaultWidgetVisibility,
  },
  kpi_collection: {
    type: widgets.KPI_COLLECTION,
    enabled: false,
    showHeader: false,
    visibility: defaultWidgetVisibility,
  },
  shortcuts: {
    type: widgets.SHORTCUTS,
    enabled: false,
    showHeader: false,
    visibility: defaultWidgetVisibility,
  },
  third_party_widget: {
    type: widgets.THIRD_PARTY_WIDGET,
    enabled: false,
    showHeader: true,
    visibility: defaultWidgetVisibility,
  },
};

export const fetchStatisticsDefaultWidgetConfig = () => {
  const widgets = _.cloneDeep(widgetsConfigDefaults);

  widgets.lead_result = setWidgetInfo(widgets.lead_result, 1);
  widgets.sales_meeting_result = setWidgetInfo(widgets.sales_meeting_result, 2);
  widgets.revenue = setWidgetInfo(widgets.revenue, 3);
  widgets.sales_meeting_booked = setWidgetInfo(widgets.sales_meeting_booked, 4);
  return widgets;
};

export const fetchDashboardDefaultWidgetConfig = () => {
  const widgets = _.cloneDeep(widgetsConfigDefaults);
  widgets.kpi_collection = setWidgetInfo(widgets.kpi_collection, 0);
  widgets.shortcuts = setWidgetInfo(widgets.shortcuts, 1);
  widgets.current_tasks = setWidgetInfo(widgets.current_tasks, 2);
  widgets.free_leads = setWidgetInfo(widgets.free_leads, 3);
  widgets.calendar = setWidgetInfo(widgets.calendar, 4);
  widgets.latest_objects = setWidgetInfo(widgets.latest_objects, 5);
  widgets.latest_contacts = setWidgetInfo(widgets.latest_contacts, 6);
  widgets.outgoing_mails = setWidgetInfo(widgets.outgoing_mails, 7);
  widgets.third_party_widget = setWidgetInfo(
    widgets.third_party_widget,
    8,
    false
  );
  return widgets;
};

const setWidgetInfo = (
  widget,
  displayOrder: number,
  enabled: boolean = true
): WidgetConfig & any => {
  return {
    ...widget,
    enabled,
    visibility: {
      admin: {
        visible: true,
        displayOrder: displayOrder,
      },
      manager: {
        visible: true,
        displayOrder: displayOrder,
      },
      broker: {
        visible: true,
        displayOrder: displayOrder,
      },
    },
  };
};
