import { Employee, Office } from "@app/models";
import { EmployeeOffices } from "@app/sidebar/profile/profile.component";
import { Role } from "../utils/roles";
import { createAction, props } from "@ngrx/store";
import { Params } from "@angular/router";

export interface Credentials {
  username: string;
  password: string;
  longLivedToken?: boolean;
}

export type Level = "promote" | "demote";

export const setRoles = createAction(
  "[User] SET_ROLES",
  props<{ roles: Role[] }>()
);

export const removeToken = createAction("[User] REMOVE_TOKEN");

export const refreshToken = createAction("[User] REFRESH_TOKEN");

export const refreshTokenSuccess = createAction("[User] REFRESH_TOKEN_SUCCESS");

export const clearState = createAction("[User] CLEAR_STATE");

export const loadRequest = createAction(
  "[User] LOAD_REQUEST",
  props<{
    eaEmployeeId: string;
    eaOfficeId: string;
  }>()
);

export const loadSuccess = createAction(
  "[User] LOAD_SUCCESS",
  props<{
    employee: Employee;
    office: Office;
    roles: string[];
  }>()
);

export const setViewingAsSomeoneElse = createAction(
  "[User] SET_VIEWING_AS_SOMEONE_ELSE",
  props<{ isViewingAsSomeoneElse: boolean }>()
);

export const clearUnauthorizedPath = createAction(
  "[User] UNAUTHORIZED_PATH_CLEARED"
);

export const navigateToUnauthorizedPath = createAction(
  "[User] UNAUTHORIZED_PATH_NAVIGATE"
);

export const loginRequest = createAction(
  "[User] LOGIN_REQUEST",
  props<{ credentials: Credentials }>()
);

export const loginSuccess = createAction(
  "[User] LOGIN_SUCCESS",
  props<{ username: string }>()
);

export const loginFail = createAction("[User] LOGIN_FAIL");

export const loadAllEmployeesAndOfficesRequest = createAction(
  "[User] LOAD_ALL_EMPLOYEES_AND_OFFICES_REQUEST",
  props<{ email: string }>()
);

export const loadAllEmployeesAndOfficesSuccess = createAction(
  "[User] LOAD_ALL_EMPLOYEES_AND_OFFICES_SUCCESS",
  props<{ employeeOffices: EmployeeOffices[] }>()
);

export const loadAllEmployeesAndOfficesFail = createAction(
  "[User] LOAD_ALL_EMPLOYEES_AND_OFFICES_FAIL"
);

export const unauthorized = createAction(
  "[User] UNAUTHORIZED",
  props<{ unauthorizedPath?: string }>()
);

export const logout = createAction("[User] LOGOUT");

export const superAdminLoginRequest = createAction(
  "[User] SUPER_ADMIN_LOGIN_REQUEST",

  props<{ credentials: Credentials }>()
);

export const superAdminLoginSuccess = createAction(
  "[User] SUPER_ADMIN_LOGIN_SUCCESS"
);

export const superAdminLoginFail = createAction(
  "[User] SUPER_ADMIN_LOGIN_FAIL"
);

export const validateOauthTokenRequest = createAction(
  "[User] VALIDATE_TOKEN_REQUEST",
  props<{ token: Params }>()
);

export const validateOauthTokenSuccess = createAction(
  "[User] VALIDATE_TOKEN_SUCCESS"
);

export const validateOauthTokenFail = createAction(
  "[User] VALIDATE_TOKEN_FAIL",
  props<{ unmatchedSSOEmail?: string; forbiddenLogin?: boolean }>()
);

export const unautorizedPathStored = createAction(
  "[User] UNAUTHORIZED_PATH_STORED",
  props<{ unauthorizedPath: string }>()
);

export const clientLessSuperAdminLoginRequest = createAction(
  "[User] CLIENT_LESS_SUPER_ADMIN_LOGIN_REQUEST",
  props<{ credentials: Credentials }>()
);

export const clientLessSuperAdminLoginSuccess = createAction(
  "[User] CLIENT_LESS_SUPER_ADMIN_LOGIN_SUCCESS"
);

export const clientLessSuperAdminLoginFail = createAction(
  "[User] CLIENT_LESS_SUPER_ADMIN_LOGIN_FAIL"
);

export const loadClientRequest = createAction("[User] LOAD_CLIENT_REQUEST");

export const loadClientSuccess = createAction(
  "[User] LOAD_CLIENT_SUCCESS",
  props<{ clients: string[] }>()
);

export const loadClientFail = createAction("[User] LOAD_CLIENT_FAIL");

export const switchClientRequest = createAction(
  "[User] SWITCH_CLIENT_REQUEST",
  props<{ clientName: string }>()
);

export const switchClientSuccess = createAction(
  "[User] SWITCH_CLIENT_SUCCESS",
  props<{ clientName: string }>()
);

export const switchClientFail = createAction("[User] SWITCH_CLIENT_FAIL");

export const switchSuperAdminLevelRequest = createAction(
  "[User] SWITCH_SUPER_ADMIN_LEVEL_REQUEST",
  props<{ level: Level }>()
);

export const switchSuperAdminLevelSuccess = createAction(
  "[User] SWITCH_SUPER_ADMIN_LEVEL_SUCCESS"
);

export const switchSuperAdminLevelFail = createAction(
  "[User] SWITCH_SUPER_ADMIN_LEVEL_FAIL"
);

export const detectClientRequest = createAction("[User] DETECT_CLIENT_REQUEST");

export const detectClientSuccess = createAction(
  "[User] DETECT_CLIENT_SUCCESS",
  props<{ clientName: string }>()
);

export const detectClientFail = createAction("[User] DETECT_CLIENT_FAIL");
