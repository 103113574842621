import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { AppState } from "@app/app.reducer";
import { NpsEntity } from "@app/models/nps";
import { getFeature } from "@app/shared/config/config.reducer";
import { NpsConfig } from "@app/shared/config/models";
import { NPS } from "@app/shared/config/utils/features";
import { API_DATE_FORMAT } from "@app/shared/utils/api-defaults";
import * as widgetActions from "@app/widgets/widgets/statistics-aktiv-nps-widget/ngrx/statistics-aktiv-nps-widget.actions";
import * as fromWidget from "@app/widgets/widgets/statistics-aktiv-nps-widget/ngrx/statistics-aktiv-nps-widget.reducer";
import { select, Store } from "@ngrx/store";
import moment from "moment";
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  first,
  map,
  Observable,
  of,
  skip,
  Subject,
  switchMap,
  takeUntil,
} from "rxjs";
import { SurveyResponse } from "@app/models/survey";
import { ApiService } from "@app/core/services/api/api.service";
import { TypedPaginationListDTO } from "@app/models";
import { API_SURVEYS_DEFAULT_LIMIT } from "@app/lists/lists-surveys/utils/lists-surveys-defaults";

@Component({
  selector: "app-statistics-aktiv-nps-widget",
  templateUrl: "./statistics-aktiv-nps-widget.component.html",
  styleUrls: ["./statistics-aktiv-nps-widget.component.scss"],
})
export class StatisticsAktivNpsWidgetComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() isAdmin: boolean;
  @Input() isManager: boolean;
  @Input() eaEmployeeId: string;
  @Input() eaOfficeId: string;
  @Input() dateFilter = "currentMonth";
  @Input() groupBy = "employee";
  @Input() triggerDataFetch$: Observable<void>;

  statistics$: Observable<NpsEntity>;
  loading$: Observable<boolean>;
  useAverageValue$: Observable<boolean>;
  surveys$: BehaviorSubject<SurveyResponse[]> = new BehaviorSubject([]);
  surveysLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  totalRows$: BehaviorSubject<number> = new BehaviorSubject(0);
  limit$: BehaviorSubject<number> = new BehaviorSubject(10);
  leftToLoad$: Observable<number>;
  showLoadMore$: Observable<boolean>;
  unsubscribe$ = new Subject<void>();

  dateFilters = [
    { label: "this_month", value: "currentMonth" },
    { label: "this_year", value: "currentYear" },
  ];

  groupFilters = [
    { label: "top_lists_filter_agent", value: "employee" },
    { label: "top_lists_filter_office", value: "office" },
  ];

  constructor(private store: Store<AppState>, private apiService: ApiService) {}

  ngOnInit(): void {
    this.mapStateToProps();

    if (this.isAdmin) {
      this.groupFilters.push({ label: "top_lists_filter_all", value: "all" });
    }

    this.triggerDataFetch$
      ?.pipe(skip(1), takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.getStatistics();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.eaEmployeeId || changes.eaOfficeId || changes.isAdmin) {
      this.getStatistics();
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  changeDateFilter(filter: string) {
    this.dateFilter = filter;
    this.getStatistics();
  }

  changeGroupFilter(filter: string) {
    this.groupBy = filter;
    this.getStatistics();
  }

  onLoadMore(): void {
    combineLatest([this.limit$, this.surveys$.pipe(map((list) => list.length))])
      .pipe(first())
      .subscribe(([limit, offset]) => {
        const surveyParams = {
          ...this.getSurveyParams(),
          limit,
          offset,
        };
        this.loadSurveys(surveyParams, true);
      });
  }

  private mapStateToProps() {
    this.statistics$ = this.store.pipe(select(fromWidget.getStats));
    this.useAverageValue$ = this.store.pipe(
      select(getFeature(NPS)),
      map((feature: NpsConfig) => feature.use_average_nps_value)
    );
    this.loading$ = this.store.pipe(select(fromWidget.getLoading));
    this.showLoadMore$ = combineLatest([
      this.totalRows$,
      this.surveys$.pipe(map((s) => (s ? s.length : 0))),
    ]).pipe(
      map(([total, loadedCount]) => loadedCount !== 0 && loadedCount < total)
    );
    this.leftToLoad$ = combineLatest([
      this.totalRows$,
      this.surveys$.pipe(map((s) => (s ? s.length : 0))),
    ]).pipe(map(([total, count]) => total - count));
  }

  private getStatistics(): void {
    const dates = this.getDates();
    const ids = this.getIds();

    const params = {
      includeAnswerFrequency: false,
      minResponsesWithNpsValue: 0,
      ...dates,
      ...ids,
    };

    this.store.dispatch(widgetActions.fetchStatisticsRequest({ params }));

    const surveyParams = this.getSurveyParams();
    // this.store.dispatch(listsSurveysActions.getSurveys(surveyParams));
    this.loadSurveys(surveyParams);
  }

  private getIds(): any {
    const params = {};

    if (this.isAdmin && this.groupBy === "all") {
      return params;
    }

    if (
      this.groupBy === "employee" &&
      !!this.eaEmployeeId &&
      this.eaEmployeeId.length > 0
    ) {
      params["eaEmployeeId"] = this.eaEmployeeId;
    }

    if (
      this.groupBy === "office" &&
      !!this.eaOfficeId &&
      this.eaOfficeId.length > 0
    ) {
      params["eaOfficeId"] = this.eaOfficeId;
    }

    return params;
  }

  private getDates() {
    switch (this.dateFilter) {
      case "currentMonth":
        return {
          resDateMin: moment().startOf("month").format(API_DATE_FORMAT),
          resDateMax: moment().format(API_DATE_FORMAT),
        };
      case "currentYear":
        return {
          resDateMin: moment().startOf("year").format(API_DATE_FORMAT),
          resDateMax: moment().format(API_DATE_FORMAT),
        };
      default:
        return {
          resDateMin: moment().format(API_DATE_FORMAT),
          resDateMax: moment().format(API_DATE_FORMAT),
        };
    }
  }

  private getSurveyParams() {
    const dates = this.getDates();
    const ids = this.getIds();

    return {
      limit: API_SURVEYS_DEFAULT_LIMIT,
      offset: 0,
      minDate: dates.resDateMin,
      maxDate: dates.resDateMax,
      NPSClass: null,
      ...ids,
    };
  }

  private loadSurveys(surveyParams: Record<string, unknown>, isMore = false) {
    this.surveysLoading$.next(true);
    this.surveys$
      .pipe(
        first(),
        switchMap((currentList) =>
          this.apiService.get("survey-responses/search", surveyParams).pipe(
            map((newList: TypedPaginationListDTO<SurveyResponse>) => ({
              currentList,
              newList,
            })),
            catchError(() => of({ currentList, newList: null }))
          )
        )
      )
      .subscribe(({ currentList, newList }) => {
        this.surveysLoading$.next(false);
        this.limit$.next(newList?.limit ?? 10);
        this.totalRows$.next(newList?.total ?? 0);

        const rows = [];
        if (isMore) {
          rows.push(...currentList);
        }

        rows.push(...(newList?.rows ?? []));
        this.surveys$.next(rows);
      });
  }
}
