<div class="wrapper">
  <sidebar-header
    *ngIf="potentialBuyer$ | async as pb"
    [type]="tabType"
    [label]="pb.firstName + ' ' + pb.familyName"
    [contactId]="pb.contactId"
    [enableEdit]="false"
  >
  </sidebar-header>

  <div class="potential-buyer__actions">
    <ng-template #noPhone>
      <p>
        <i class="icon-phone"></i>
        <span class="muted" translate>missing_phone</span>
      </p>
    </ng-template>

    <ng-container
      *ngIf="
        (msisdn$ | async)?.length > 0 || (phoneNumber$ | async)?.length > 0;
        else noPhone
      "
    >
      <a
        *ngIf="(msisdn$ | async)?.length > 0"
        href="tel:+{{ msisdn$ | async }}"
      >
        <button class="q-btn--save">
          {{ "call" | translate }}:
          {{ msisdn$ | async | phoneFormat : (msisdnCountry$ | async) }}
        </button>
      </a>
      <a
        *ngIf="
          (msisdn$ | async)?.length === 0 && (phoneNumber$ | async)?.length > 0
        "
        href="tel:+{{ phoneNumber$ | async }}"
      >
        <button class="q-btn--save">
          {{ "call" | translate }}:
          {{
            phoneNumber$ | async | phoneFormat : (phoneNumberCountry$ | async)
          }}
        </button>
      </a>
    </ng-container>
    <button class="q-btn--save" (click)="openSendMessageSidebar()">
      {{ "send_message" | translate }}
      <app-icon name="envelope"></app-icon>
    </button>
    <button
      class="q-btn--remove"
      (click)="removeAsPotentialBuyer()"
      [matTooltip]="
        (biddingService?.getHighestBidForContact() | async)
          ? ('you_cant_remove_potential_buyers_who_have_bidded' | translate)
          : !(isResponsibleBroker$ | async)
          ? ('only_responsible_broker_can_remove_potential_buyers' | translate)
          : null
      "
      matTooltipClass="q-tooltip"
    >
      <ng-container>
        {{ "remove_from_potentialbuyers" | translate }}
        <app-icon name="trash"></app-icon>
      </ng-container>
    </button>

    <ng-container *ngIf="sendPotentialBuyerService.hasIntegration$ | async">
      <app-send-potential-buyers
        [disabled]="!(hasAllRequiredData$ | async)"
        [sending]="sendPotentialBuyerService.isSending() | async"
        [progress]="sendPotentialBuyerService.getSendingProgress() | async"
        [errors]="sendPotentialBuyerService.getErrors() | async"
        [externalProviderName]="externalProviderName$ | async"
        [textLine]="'send_as_potential_buyer_to_erp'"
        (send)="handleSendPotentialBuyersToERP()"
      >
      </app-send-potential-buyers>
    </ng-container>
  </div>

  <div class="scroll-view">
    <hr />
    <section class="showings">
      <div class="showing-status">
        <h4 translate>showing_status</h4>
        <app-q-circle-btn
          *ngIf="showCreatePrivateShowing$ | async"
          [icon]="'plus'"
          [tooltipText]="'create_showing'"
          (click)="openAddShowingModal()"
        ></app-q-circle-btn>
      </div>
      <button
        class="q-btn--save"
        (click)="openShowingAttendanceModal()"
        translate
      >
        manage_showing_attendance
      </button>
    </section>

    <hr />

    <section>
      <ng-container *ngIf="note$ | async as note">
        <h4 translate>latest_comment</h4>
        <app-expandable-text
          *ngIf="note?.note"
          [text]="note?.note"
        ></app-expandable-text>
        <small
          *ngIf="note?.employeeFullName && note?.note"
          class="text-muted metadata"
        >
          {{ "written_by" | translate }}: {{ note?.employeeFullName }}
        </small>
      </ng-container>
      <h4 translate>write_comment</h4>
      <app-create-note
        [value]="noteValue"
        (noteValueChanged)="handleNoteValueChanged($event)"
        (create)="handleCreateNote($event)"
      >
      </app-create-note>
    </section>

    <hr />

    <section
      class="option"
      [class.loading-pulse]="contactService.loading$ | async"
    >
      <app-contacted-status
        [status]="
          (potentialBuyer$ | async).lastContacts
            | contactedStatusFromLastContacts
        "
        (statusChange)="updateContactedStatus($event)"
        class="option__row"
      >
      </app-contacted-status>

      <app-bidding-status
        *ngIf="potentialBuyer$ | async as pb"
        [status]="pb?.bidStatus"
        [isWorking]="isWorking$ | async"
        (statusChange)="handleBiddingStatusChange($event)"
        class="option__row"
      >
        <div bidding class="bidding" *ngIf="showBiddingInfo$ | async">
          <div class="bidding__bid" *ngIf="canAddBid$ | async as canAddBid">
            <div class="bidding__bid--container">
              <span *ngIf="highestBid$ | async as hb; else noBid"
                >{{ "highest_bid" | translate }} :
                {{ hb?.amount | qNumber }}</span
              >
              <ng-template #noBid>{{ "no_bid_added" | translate }}</ng-template>
              <app-q-circle-btn
                *ngIf="canAddBid.enabled"
                (click)="canAddBid.enabled ? handleAddBid() : ''"
                [class.btn-disabled]="!canAddBid.enabled"
                [icon]="'plus'"
                [tooltipText]="'add_bid'"
              ></app-q-circle-btn>
            </div>
            <ng-container
              *ngIf="supportedDeepLinks$ | async as supportedDeepLinks"
            >
              <div
                *ngIf="
                  supportedDeepLinks.includes(
                    supportedDeepLinksEnum.SET_WINNING_BID
                  )
                "
              >
                <ng-container *ngIf="highestBid$ | async as bid">
                  <a
                    *ngIf="canAddBid.enabled"
                    class="bidding__bid--settings-link"
                    (click)="goToSetAsWinningBid(bid.externalBidId)"
                  >
                    {{ "set_as_winning_bid" | translate }}
                  </a>
                </ng-container>
              </div>
              <div
                *ngIf="
                  supportedDeepLinks.includes(
                    supportedDeepLinksEnum.BIDDER_SETTINGS
                  )
                "
              >
                <a
                  class="bidding__bid--settings-link"
                  (click)="goToSettingsForBidder()"
                >
                  {{ "settings_for_bidder" | translate }}
                </a>
              </div>
            </ng-container>
          </div>
        </div>
      </app-bidding-status>

      <app-loan-commitment
        [status]="(potentialBuyer$ | async)?.profile?.mortgageStatus"
        (sendTipToBankClicked)="openSendTipToBankSidebar()"
        (statusChange)="handleLoanCommitmentStatusChange($event)"
        [enableSendTipToBank]="enableSendTipToBank$ | async"
        class="option__row"
      >
      </app-loan-commitment>

      <app-owns-residence
        [status]="(potentialBuyer$ | async)?.profile?.ownsResidence"
        (statusChange)="handleResidenceStatusChange($event)"
        class="option__row"
      >
      </app-owns-residence>
    </section>
    <hr />
    <section [class.loading-pulse]="actionService.loading$ | async">
      <contact-action-list
        [actions]="actionService.currentList$ | async"
      ></contact-action-list>
    </section>
    <hr />
  </div>
</div>
