import { Component, OnDestroy, OnInit } from "@angular/core";
import { AppState } from "@app/app.reducer";
import {
  getStatisticsWidgets,
  getWidgets,
} from "@app/shared/config/config.reducer";
import * as fromUser from "@app/shared/user";
import { UserIds } from "@app/shared/user";
import { select, Store } from "@ngrx/store";
import {
  BehaviorSubject,
  combineLatest,
  filter,
  map,
  Observable,
  Subject,
  switchMap,
  takeUntil,
} from "rxjs";
import { WidgetConfig } from "@app/shared/config/models/widgets-page-config";
import { Role } from "@app/shared/utils/roles";
import {
  isSupportedWidget,
  supportedExternalWidgets,
} from "@app/shared/modules/pages/external-widget-main/supported-external-widgets";
import { ActivatedRoute } from "@angular/router";
import * as widgets from "@app/statistics/utils/supported-widgets";

@Component({
  selector: "external-widget-main",
  templateUrl: "./external-widget-main.component.html",
  styleUrls: ["./external-widget-main.component.scss"],
})
export class ExternalWidgetMainComponent implements OnInit, OnDestroy {
  role$: Observable<Role>;
  userIds$: Observable<UserIds>;
  allWidgets$: Observable<WidgetConfig[]>;
  widgets$: BehaviorSubject<WidgetConfig[]> = new BehaviorSubject<
    WidgetConfig[]
  >(null);
  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private store: Store<AppState>, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.mapStateToProps();

    this.route.queryParams
      .pipe(
        map((params) => params?.page),
        filter((widgetName) => !!widgetName),
        filter((widgetName) => !!isSupportedWidget(widgetName)),
        switchMap((widgetName) => this.setWidget(widgetName)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private mapStateToProps(): void {
    this.userIds$ = this.store.pipe(fromUser.getEaIds);
    this.role$ = this.store.pipe(select(fromUser.getHighestRole));

    this.allWidgets$ = this.role$.pipe(
      filter((role) => !!role),
      switchMap((role) =>
        combineLatest([
          this.store.pipe(getWidgets("dashboard_widgets", role)),
          this.store.pipe(getStatisticsWidgets("start_page", role)),
        ])
      ),
      map(([dashboardWidgets, statisticsWidgets]) => [
        ...dashboardWidgets,
        ...statisticsWidgets,
        {
          type: widgets.KPI_COLLECTION,
          enabled: true,
          visibility: {
            broker: {
              displayOrder: 1,
              visible: true,
              defaultQuickFilter: "currentMonth",
            },
            manager: {
              displayOrder: 1,
              visible: true,
              defaultQuickFilter: "currentMonth",
            },
            admin: {
              displayOrder: 1,
              visible: true,
              defaultQuickFilter: "currentMonth",
            },
          },
        },
        {
          type: widgets.TOP_LISTS,
          enabled: true,
          visibility: {
            broker: {
              displayOrder: 1,
              visible: true,
              defaultQuickFilter: "currentMonth",
            },
            manager: {
              displayOrder: 1,
              visible: true,
              defaultQuickFilter: "currentMonth",
            },
            admin: {
              displayOrder: 1,
              visible: true,
              defaultQuickFilter: "currentMonth",
            },
          },
        },
        {
          type: widgets.AKTIV_NPS,
          enabled: true,
          visibility: {
            broker: {
              displayOrder: 1,
              visible: true,
              defaultQuickFilter: "currentMonth",
            },
            manager: {
              displayOrder: 1,
              visible: true,
              defaultQuickFilter: "currentMonth",
            },
            admin: {
              displayOrder: 1,
              visible: true,
              defaultQuickFilter: "currentMonth",
            },
          },
        },
      ]),
      takeUntil(this.unsubscribe$)
    );
  }

  private setWidget(widgetName: string) {
    return this.allWidgets$.pipe(
      map((widgets) => {
        let selectedWidget: WidgetConfig = null;
        if (widgets && widgets.length > 0) {
          selectedWidget = widgets.find(
            (widget) => widget.type === supportedExternalWidgets[widgetName]
          );
        }

        if (selectedWidget) {
          this.widgets$.next([selectedWidget]);
        }
      })
    );
  }
}
