import {
  contactRelationsWidgetReducer,
  ContactRelationsWidgetState,
} from "@app/widgets/widgets/contact-widgets/contact-relations-widget/ngrx/contact-relations-widget.reducer";
import {
  contactStatusWidgetReducer,
  ContactStatusWidgetState,
} from "@app/widgets/widgets/contact-widgets/contact-status-widget/ngrx/contact-status-widget.reducer";
import {
  latestContactsWidgetReducer,
  LatestContactsWidgetState,
} from "@app/widgets/widgets/latest-contacts-widget/ngrx/latest-contacts-widget.reducer";
import {
  latestObjectsForSaleWidgetReducer,
  LatestObjectsForSaleWidgetState,
} from "@app/widgets/widgets/latest-objects-for-sale-widget/ngrx/latest-objects-for-sale-widget.reducer";
import {
  latestObjectsWidgetReducer,
  LatestObjectsWidgetState,
} from "@app/widgets/widgets/latest-objects-widget/ngrx/latest-objects-widget.reducer";
import {
  objectNotesReducer,
  ObjectNotesState,
} from "@app/widgets/widgets/object-widgets/object-notes-widget/ngrx/object-notes-widget.reducer";
import {
  budgetReducer,
  BudgetState,
} from "@app/widgets/widgets/statistics-budget-widget/ngrx/statistics-budget-widget.reducer";
import {
  StatisticsEmployeesPerformanceState,
  statisticsEmployeesPerformanceWidgetReducer,
} from "@app/widgets/widgets/statistics-employees-performance-widget/ngrx/statistics-employees-performance-widget.reducer";
import {
  leadResultsReducer,
  LeadResultState,
} from "@app/widgets/widgets/statistics-lead-result-widget-new/ngrx/statistics-lead-result-widget.reducer";
import {
  npsReducer,
  NpsState,
} from "@app/widgets/widgets/statistics-nps-widget/ngrx/statistics-nps-widget.reducer";
import {
  aktivNpsReducer,
  AktivNpsState,
} from "@app/widgets/widgets/statistics-aktiv-nps-widget/ngrx/statistics-aktiv-nps-widget.reducer";
import {
  objectStatusReducer,
  ObjectStatusStatisticsState,
} from "@app/widgets/widgets/statistics-object-status-widget/ngrx/statistics-object-status-widget.reducer";
import {
  revenueReducer,
  RevenueState,
} from "@app/widgets/widgets/statistics-revenue-widget/ngrx/statistics-revenue-widget.reducer";
import {
  soldObjectsReducer,
  SoldObjectsState,
} from "@app/widgets/widgets/statistics-sold-objects-widget/ngrx/statistics-sold-objects-widget.reducer";
import {
  summaryReducer,
  SummaryState,
} from "@app/widgets/widgets/statistics-summary-widget/ngrx/statistics-summary-widget.reducer";
import {
  statisticsTailoredCampaignWidgetReducer,
  TailoredCampaignState,
} from "@app/widgets/widgets/statistics-tailored-campaign-widget/ngrx/statistics-tailored-campaign-widget.reducer";
import { combineReducers } from "@ngrx/store";
import {
  ShortcutsReducer,
  ShortcutsState,
} from "@app/widgets/widgets/shortcuts-widget/ngrx/shortcuts-widget.reducer";
import {
  topListsReducer,
  TopListsState,
} from "@app/widgets/widgets/statistics-top-list/ngrx/statistics-top-list-widget.reducer";

export interface WidgetsModuleState {
  leadResult: LeadResultState;
  revenue: RevenueState;
  soldObjects: SoldObjectsState;
  nps: NpsState;
  aktivNps: AktivNpsState;
  summary: SummaryState;
  topLists: TopListsState;
  budget: BudgetState;
  objectStatus: ObjectStatusStatisticsState;
  latestObjects: LatestObjectsWidgetState;
  latestObjectsForSale: LatestObjectsForSaleWidgetState;
  latestContacts: LatestContactsWidgetState;
  employeesPerformance: StatisticsEmployeesPerformanceState;
  contactStatus: ContactStatusWidgetState;
  contactRelations: ContactRelationsWidgetState;
  objectNotes: ObjectNotesState;
  tailoredCampaign: TailoredCampaignState;
  shortcuts: ShortcutsState;
}

const reducers = {
  leadResult: leadResultsReducer,
  revenue: revenueReducer,
  soldObjects: soldObjectsReducer,
  nps: npsReducer,
  aktivNps: aktivNpsReducer,
  summary: summaryReducer,
  topLists: topListsReducer,
  budget: budgetReducer,
  objectStatus: objectStatusReducer,
  latestObjects: latestObjectsWidgetReducer,
  latestObjectsForSale: latestObjectsForSaleWidgetReducer,
  latestContacts: latestContactsWidgetReducer,
  employeesPerformance: statisticsEmployeesPerformanceWidgetReducer,
  contactStatus: contactStatusWidgetReducer,
  contactRelations: contactRelationsWidgetReducer,
  objectNotes: objectNotesReducer,
  tailoredCampaign: statisticsTailoredCampaignWidgetReducer,
  shortcuts: ShortcutsReducer,
};

export function widgetsModuleReducer(state: any, action: any) {
  return combineReducers(reducers)(state, action);
}
