<ng-container *ngFor="let widget of widgetConfig">
  <app-widget
    [widgetType]="widget.type"
    [size]="getSize(widget.type)"
    [showMoreLink]="getShowMoreLink(widget.type)"
    [padding]="getPadding(widget.type)"
    [openLinkInNewTab]="openLinkInNewTab"
  >
    <ng-container *ngIf="role$ | async as role" [ngSwitch]="widget.type">
      <ng-container [ngSwitch]="widget.type">
        <ng-container *ngSwitchCase="widgets.OBJECT_STATUS">
          <app-statistics-object-status-widget
            [eaEmployeeId]="roleBasedEaEmployeeId$ | async"
            [eaOfficeId]="roleBasedEaOfficeId$ | async"
            [objectStatuses]="objectStatuses"
          >
          </app-statistics-object-status-widget>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.ADFENIX_MARKETING_REPORTS">
          <app-statistics-adfenix-reporting-widget
            [eaOfficeId]="roleBasedEaOfficeId$ | async"
            [showHeader]="showHeader"
          >
          </app-statistics-adfenix-reporting-widget>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.ADFENIX_MARKETING_REPORTS_SUMMARY">
          <app-statistics-adfenix-reporting-summary-widget
            [eaOfficeId]="roleBasedEaOfficeId$ | async"
            [showHeader]="showHeader"
          >
          </app-statistics-adfenix-reporting-summary-widget>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.REVENUE">
          <app-statistics-revenue-widget
            [eaEmployeeId]="roleBasedEaEmployeeId$ | async"
            [eaOfficeId]="roleBasedEaOfficeId$ | async"
          ></app-statistics-revenue-widget>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.SOLD_OBJECTS">
          <app-statistics-sold-objects-widget
            [eaEmployeeId]="roleBasedEaEmployeeId$ | async"
            [eaOfficeId]="roleBasedEaOfficeId$ | async"
            [returnType]="widget.returnType"
            [showHeader]="showHeader"
          ></app-statistics-sold-objects-widget>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.NPS">
          <app-statistics-nps-widget
            [eaEmployeeId]="roleBasedEaEmployeeId$ | async"
            [eaOfficeId]="roleBasedEaOfficeId$ | async"
            [quickFilter]="widget.visibility[role].defaultQuickFilter"
          ></app-statistics-nps-widget>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.SUMMARY">
          <app-statistics-summary-widget
            [eaEmployeeId]="roleBasedEaEmployeeId$ | async"
            [eaOfficeId]="roleBasedEaOfficeId$ | async"
            [returnTypes]="returnTypes"
            [quickFilter]="widget.visibility[role].defaultQuickFilter"
          ></app-statistics-summary-widget>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.TOP_LISTS">
          <app-statistics-top-list-widget
            [eaEmployeeId]="eaEmployeeId"
            [eaOfficeId]="eaOfficeId"
          ></app-statistics-top-list-widget>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.AKTIV_NPS">
          <app-statistics-aktiv-nps-widget
            [eaEmployeeId]="eaEmployeeId"
            [eaOfficeId]="eaOfficeId"
            [isAdmin]="isAdmin$ | async"
            [isManager]="isManager$ | async"
          ></app-statistics-aktiv-nps-widget>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.BUDGET">
          <app-statistics-budget-widget
            [eaEmployeeId]="roleBasedEaEmployeeId$ | async"
            [eaOfficeId]="roleBasedEaOfficeId$ | async"
          ></app-statistics-budget-widget>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.LEAD_RESULT">
          <app-statistics-lead-result-widget-new
            [eaEmployeeIds]="[roleBasedEaEmployeeId$ | async]"
            [eaOfficeIds]="[roleBasedEaOfficeId$ | async]"
            [taskTypeIds]="widget?.taskTypes"
            [quickFilter]="widget.visibility[role].defaultQuickFilter"
            [showHeader]="showHeader"
          ></app-statistics-lead-result-widget-new>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.SALES_MEETING_RESULT">
          <ng-container *ngTemplateOutlet="unidentifiedWidget"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.SALES_MEETING_BOOKED">
          <ng-container *ngTemplateOutlet="unidentifiedWidget"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.CURRENT_TASKS">
          <app-current-tasks-widget
            [eaEmployeeId]="eaEmployeeId"
            [openLinkInNewTab]="openLinkInNewTab"
            [showHeader]="showHeader"
          ></app-current-tasks-widget>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.TASKS">
          <app-tasks-widget
            [eaEmployeeId]="eaEmployeeId"
            [openLinkInNewTab]="openLinkInNewTab"
            [showHeader]="showHeader"
          ></app-tasks-widget>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.OVERDUE_TASKS">
          <app-overdue-tasks-widget
            [eaEmployeeId]="eaEmployeeId"
            [openLinkInNewTab]="openLinkInNewTab"
            [showHeader]="showHeader"
          ></app-overdue-tasks-widget>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.OUTGOING_MAIL">
          <app-outgoing-mail-widget></app-outgoing-mail-widget>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.FREE_LEADS">
          <app-free-leads-widget
            [eaEmployeeId]="eaEmployeeId"
            [eaOfficeId]="eaOfficeId"
            [openLinkInNewTab]="openLinkInNewTab"
            [showHeader]="showHeader"
          ></app-free-leads-widget>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.BROKER_TIMELINE">
          <app-broker-timeline-widget
            [eaEmployeeId]="eaEmployeeId"
          ></app-broker-timeline-widget>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.LATEST_OBJECTS">
          <app-latest-objects-widget
            [openLinkInNewTab]="openLinkInNewTab"
          ></app-latest-objects-widget>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.LATEST_OBJECTS_FOR_SALE">
          <app-latest-objects-for-sale-widget
            [eaOfficeId]="roleBasedEaOfficeId$ | async"
          ></app-latest-objects-for-sale-widget>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.LATEST_CONTACTS">
          <app-latest-contacts-widget
            [openLinkInNewTab]="openLinkInNewTab"
          ></app-latest-contacts-widget>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.CALENDAR">
          <app-calendar-widget
            [openLinkInNewTab]="openLinkInNewTab"
          ></app-calendar-widget>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.MORE_BUSINESS_OPPORTUNITIES">
          <app-more-business-opportunities-widget
            [eaEmployeeId]="eaEmployeeId"
            [eaOfficeId]="eaOfficeId"
          ></app-more-business-opportunities-widget>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.EMPLOYEES_PERFORMANCE">
          <app-statistics-employees-performance-widget
            [eaEmployeeId]="eaEmployeeId"
            [eaOfficeId]="eaOfficeId"
            [config]="widget"
          ></app-statistics-employees-performance-widget>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.CONTACT_DETAILS">
          <app-contact-details-widget
            [contactId]="contactId"
          ></app-contact-details-widget>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.COMPANY_DETAILS">
          <app-company-details-widget
            [contactId]="contactId"
          ></app-company-details-widget>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.CONTACT_NEXT_STEP">
          <app-contact-next-step-widget
            [contactId]="contactId"
            [eaEmployeeId]="eaEmployeeId"
          ></app-contact-next-step-widget>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.CONTACT_NOTES">
          <app-contact-notes-widget
            [contactId]="contactId"
          ></app-contact-notes-widget>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.CONTACT_OBJECTS">
          <app-contact-objects-widget
            [contactId]="contactId"
          ></app-contact-objects-widget>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.CONTACT_RESIDENCES">
          <app-contact-residences-widget
            [contactId]="contactId"
          ></app-contact-residences-widget>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.CONTACT_STATUS">
          <app-contact-status-widget
            [contactId]="contactId"
            [eaEmployeeId]="eaEmployeeId"
          ></app-contact-status-widget>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.CONTACT_RELATIONS">
          <app-contact-relations-widget
            [contactId]="contactId"
          ></app-contact-relations-widget>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.PROJECT_OVERVIEW">
          <app-project-overview-widget
            [eaOid]="eaOid"
          ></app-project-overview-widget>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.PROJECT_OBJECT_LIST">
          <app-project-object-list-widget
            [eaOid]="eaOid"
          ></app-project-object-list-widget>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.OBJECT_UPCOMING_SHOWINGS">
          <app-object-upcoming-showings-widget
            [eaOid]="eaOid"
          ></app-object-upcoming-showings-widget>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.OBJECT_OVERVIEW">
          <app-object-overview-widget
            [eaOid]="eaOid"
          ></app-object-overview-widget>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.OBJECT_PARTIES">
          <app-object-parties-widget
            [eaOid]="eaOid"
          ></app-object-parties-widget>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.OBJECT_DESCRIPTION">
          <app-object-descriptions-widget
            [eaOid]="eaOid"
          ></app-object-descriptions-widget>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.OBJECT_HIGHEST_BID">
          <app-object-highest-bid-widget
            [eaOid]="eaOid"
          ></app-object-highest-bid-widget>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.OBJECT_IMAGES">
          <app-object-image-carousel-widget
            [eaOid]="eaOid"
          ></app-object-image-carousel-widget>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.RENTAL_OVERVIEW">
          <app-rental-overview-widget
            [eaOid]="eaOid"
          ></app-rental-overview-widget>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.OBJECT_NOTES">
          <app-object-notes-widget [eaOid]="eaOid"></app-object-notes-widget>
        </ng-container>

        <ng-container *ngSwitchCase="widgets.ADFENIX_PROPERTY_REPORTING">
          <app-adfenix-property-reporting-widget
            [eaOid]="eaOid"
          ></app-adfenix-property-reporting-widget>
        </ng-container>

        <ng-container *ngSwitchCase="widgets.VALUE_MONITOR">
          <app-value-monitor-widget
            [eaOid]="eaOid"
            [contactId]="contactId"
            [eaEmployeeId]="eaEmployeeId"
          ></app-value-monitor-widget>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.TAILORED_CAMPAIGN">
          <app-statistics-tailored-campaign-widget [eaOfficeId]="eaOfficeId">
          </app-statistics-tailored-campaign-widget>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.ERA_INTRANET">
          <app-era-intranet-widget></app-era-intranet-widget>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.KPI_COLLECTION">
          <app-kpi-collection-widget
            [showHeader]="showHeader && widget?.showHeader"
            [openLinkInNewTab]="openLinkInNewTab"
          ></app-kpi-collection-widget>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.SHORTCUTS">
          <app-shortcuts-widget
            [showHeader]="showHeader && widget?.showHeader"
            [openLinkInNewTab]="openLinkInNewTab"
          ></app-shortcuts-widget>
        </ng-container>
        <ng-container *ngSwitchCase="widgets.THIRD_PARTY_WIDGET">
          <app-third-party-iframe-widget
            [showHeader]="showHeader && widget?.showHeader"
          ></app-third-party-iframe-widget>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <ng-container *ngTemplateOutlet="unidentifiedWidget"></ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </app-widget>
</ng-container>

<ng-template #unidentifiedWidget>
  <h3>
    <app-icon name="question" size="large"></app-icon>&nbsp;
    <span translate>unidentified_widget</span>
  </h3>
  <div class="widget-container">
    <p translate>unable_to_load</p>
  </div>
</ng-template>
