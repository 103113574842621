import { ExternalPresentableLink } from "@app/contacts/contact-sales-meetings/contact-sales-meetings.effects";
import { QObject } from "@app/models";
import { Project } from "@app/showings/models/project";
import { createAction, props } from "@ngrx/store";

export const setShowingObjectEaOid = createAction(
  "[Showings] SET_SHOWING_OBJECT_EAOID",
  props<{ eaOid: string }>()
);

export const setPDFLoadingRequest = createAction(
  "[Showings] SET_PDF_LOADING_REQUEST",
  props<{ loading: boolean }>()
);

export const getPDFRequest = createAction(
  "[Showings] GET_PDF_REQUEST",
  props<{ eaOid: string; filters: Record<string, unknown> }>()
);

export const getPDFSuccess = createAction(
  "[Showings] GET_PDF_SUCCESS",
  props<{ data: Blob }>()
);

export const getPDFFailure = createAction("[Showings] GET_PDF_FAILURE");

export const getShowingPDFRequest = createAction(
  "[Showings] GET_SHOWING_PDF_REQUEST",
  props<{ showingId: string }>()
);

export const getShowingPDFSuccess = createAction(
  "[Showings] GET_SHOWING_PDF_SUCCESS",
  props<{ data: Blob }>()
);

export const getShowingPDFFailure = createAction(
  "[Showings] GET_SHOWING_PDF_FAILURE"
);

export const getSetAsWinningBidLinkRequest = createAction(
  "[Showings] GET_SET_AS_WINNING_BID_LINK_REQUEST",
  props<{ bidId: string }>()
);

export const getSetAsWinningBidLinkSuccess = createAction(
  "[Showings] GET_SET_AS_WINNING_BID_LINK_SUCCESS",
  props<{ payload: ExternalPresentableLink }>()
);

export const getSetAsWinningBidLinkFailure = createAction(
  "[Showings] GET_SET_AS_WINNING_BID_LINK_FAILURE"
);

export const getBiddingSettingsLinkRequest = createAction(
  "[Showings] GET_BIDDING_SETTINGS_LINK_REQUEST",
  props<{ eaOid: string }>()
);

export const getBiddingSettingsLinkSuccess = createAction(
  "[Showings] GET_BIDDING_SETTINGS_LINK_SUCCESS",
  props<{ url: string }>()
);

export const getBiddingSettingsLinkFailure = createAction(
  "[Showings] GET_BIDDING_SETTINGS_LINK_FAILURE"
);

export const deletePrivateShowingRequest = createAction(
  "[Showings] DELETE_PRIVATE_SHOWING_REQUEST",
  props<{ showingId: string; eaOid: string }>()
);

export const deletePrivateShowingSuccess = createAction(
  "[Showings] DELETE_PRIVATE_SHOWING_SUCCESS",
  props<{ eaOid: string }>()
);

export const deletePrivateShowingFailure = createAction(
  "[Showings] DELETE_PRIVATE_SHOWING_FAILURE"
);

export const getProjectRequest = createAction(
  "[Showings] GET_PROJECT_REQUEST",
  props<{ eaOid: string }>()
);

export const getProjectSuccess = createAction(
  "[Showings] GET_PROJECT_SUCCESS",
  props<{ project: Project }>()
);

export const getProjectFailure = createAction("[Showings] GET_PROJECT_FAILURE");

export const getObjectRequest = createAction(
  "[Showings] GET_OBJECT_REQUEST",
  props<{ eaOid: string; params: Record<string, unknown> }>()
);

export const getObjectSuccess = createAction(
  "[Showings] GET_OBJECT_SUCCESS",
  props<{ property: QObject }>()
);

export const getObjectFailure = createAction("[Showings] GET_OBJECT_FAILURE");
