<div class="header-flex">
  <div class="header">
    <img src="/assets/img/nps.svg" alt="" />
    <h3 translate>nps</h3>
  </div>
  <div class="flex">
    <div *ngIf="isAdmin || isManager" style="min-width: 180px">
      <app-segmented-controls
        [controls]="groupFilters"
        [active]="groupBy"
        (change)="changeGroupFilter($event)"
      ></app-segmented-controls>
    </div>
    <div style="min-width: 190px">
      <app-segmented-controls
        [controls]="dateFilters"
        [active]="dateFilter"
        (change)="changeDateFilter($event)"
      ></app-segmented-controls>
    </div>
  </div>
</div>

<div class="widget-container" [class.loading-pulse]="loading$ | async">
  <div
    class="widget-container__data"
    *ngIf="statistics$ | async as stats; else noData"
  >
    <span *ngIf="useAverageValue$ | async; else standardValue">
      {{ stats.averageNPSValue | qNumber }}
    </span>
    <ng-template #standardValue>
      <span>{{ stats.NPSValue | qNumber }}</span>
    </ng-template>
    <p>
      {{
        "based_on_responses"
          | translate : { responses: stats.responsesWithNPSValues }
      }}
    </p>
  </div>

  <ng-container *ngIf="surveys$ | async as list">
    <div class="flex-box">
      <app-list-table
        [list]="list"
        [loading]="surveysLoading$ | async"
        [hasExtraPadding]="showLoadMore$ | async"
      ></app-list-table>

      <div *ngIf="showLoadMore$ | async" class="btn-more-container">
        <span (click)="onLoadMore()" class="btn-more" translate>
          load_more
        </span>
      </div>
    </div>
  </ng-container>
  <ng-template #noData>
    <div class="no-data">
      <span translate>no_data_found</span>
    </div>
  </ng-template>
</div>
