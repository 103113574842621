import { Component, OnDestroy, OnInit } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { ContactService } from "@app/core/ngrx/entity-services/contact.service";
import { ObjectService } from "@app/core/ngrx/entity-services/object.service";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { FeatureConfigManagerService } from "@app/core/services/feature-config-manager/feature-config-manager.service";
import { BiddingService } from "@app/integrations/bidding/services/bidding.service";
import { SendPotentialBuyersService } from "@app/integrations/bidding/services/send-potential-buyers.service";
import { QObject } from "@app/models";
import * as fromConfig from "@app/shared/config/config.reducer";
import { getFeature } from "@app/shared/config/config.reducer";
import { ExternalProviderFeature } from "@app/shared/config/models/external-provider";
import {
  CONSENT_ALARM_LEVEL,
  CREATE_PRIVATE_SHOWING,
  EXTERNAL_PROVIDER,
  POTENTIAL_BUYERS,
} from "@app/shared/config/utils/features";
import { TaskSet } from "@app/shared/modules/progress-widget/models/TaskSet";
import { addTaskSetToQueue } from "@app/shared/modules/progress-widget/ngrx/progress-widget.actions";
import { QModalService } from "@app/shared/modules/ui-components/q-modal/q-modal.service";
import { SIDEBAR_SEND_MESSAGE_URL } from "@app/shared/utils/sidebar-tab-utils";
import {
  MANAGE_POTENTIAL_BUYER_MULTIPLE,
  MANAGE_POTENTIAL_BUYER_SINGLE,
} from "@app/shared/utils/tab-types";
import { ShowingAttendanceModalComponent } from "@app/showings/components/showing-attendance-modal/showing-attendance-modal.component";
import {
  ContactablePotentialBuyer,
  PotentialBuyer,
} from "@app/showings/models";
import * as fromShowings from "@app/showings/ngrx/showings/showings.reducer";
import { PotentialBuyerService } from "@app/showings/services/potential-buyer.service";
import { reset } from "@app/sidebar/potential-buyer/ngrx/potential-buyer.ngrx";
import { TemplateGroups } from "@app/sidebar/send-message/models";
import * as SendMessageActions from "@app/sidebar/send-message/ngrx/send-message.actions";
import { AddShowingModalNewComponent } from "@app/sidebar/shared/add-showing-modal-new/add-showing-modal-new.component";
import { closeTab } from "@app/sidebar/ngrx/sidebar.actions";
import { select, Store } from "@ngrx/store";
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  first,
  map,
  Observable,
  Subject,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from "rxjs";
import { ShowingModalData } from "@app/sidebar/models/showing-modal-data";
import { PotentialBuyersFeature } from "@app/shared/config/models/potential-buyers-feature";
import { hasIntegration } from "@app/integrations/ngrx/integrations.reducer";
import { IntegrationResource } from "@app/integrations/models/enums";

@Component({
  selector: "app-potential-buyer-manage-multiple",
  templateUrl: "./potential-buyer-manage-multiple.component.html",
  styleUrls: [
    "../../../sidebar.component.common.scss",
    "./potential-buyer-manage-multiple.component.scss",
  ],
})
export class PotentialBuyerManageMultipleComponent
  implements OnInit, OnDestroy
{
  tabType = MANAGE_POTENTIAL_BUYER_MULTIPLE;

  eaOid$: Observable<string>;
  externalProviderName$: Observable<string>;
  potentialBuyers$: Observable<PotentialBuyer[]>;
  potentialBuyersWithoutPhone$: Observable<PotentialBuyer[]>;
  mobilePhoneIsRequired$: Observable<boolean>;
  object$: Observable<QObject>;
  hasExternalPotentialBuyerState$: Observable<boolean>;
  showCreatePrivateShowing$: Observable<boolean>;
  checkConsentAlarmLevelEnable$: Observable<boolean>;
  isWorking$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  callingListContactIds$: BehaviorSubject<string[]> = new BehaviorSubject<
    string[]
  >([]);
  unsubscribe$: Subject<void> = new Subject<void>();
  showSelectedContacts = false;
  showCreateCallingListSidebar = false;

  constructor(
    private store: Store<AppState>,
    public pbService: PotentialBuyerService,
    public biddingService: BiddingService,
    public sendPotentialBuyerService: SendPotentialBuyersService,
    private modalService: QModalService,
    public featureConfigManagerService: FeatureConfigManagerService,
    private objectService: ObjectService,
    public contactService: ContactService
  ) {}

  ngOnInit(): void {
    this.mapStateToProps();
    this.store.dispatch(closeTab({ tabType: MANAGE_POTENTIAL_BUYER_SINGLE }));
    this.sendPotentialBuyerService.reset();
  }

  ngOnDestroy(): void {
    this.store.dispatch(reset());
    this.sendPotentialBuyerService.reset();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openAddShowingModal() {
    combineLatest([
      this.pbService.selectedPotentialBuyers$.pipe(
        map((pbs) => pbs.map((pb) => pb.contactId))
      ),
      this.eaOid$,
    ])
      .pipe(first())
      .subscribe(([contactIds, eaOid]) => {
        const data: ShowingModalData = {
          isEditMode: false,
          contactId: contactIds[0],
          eaOid,
          hasHeader: true,
          hasHeaderCloseButton: true,
          hasActionBar: true,
        };

        this.modalService.show(AddShowingModalNewComponent, {
          data,
        });

        this.closeSidebar();
      });
  }

  openShowingAttendanceModal() {
    this.pbService.selectedPotentialBuyers$
      .pipe(
        map((pbs) => pbs.map((pb) => pb.contactId)),
        withLatestFrom(this.eaOid$),
        first()
      )
      .subscribe(([contactIds, eaOid]) => {
        this.modalService.show(ShowingAttendanceModalComponent, {
          data: {
            hasHeader: true,
            hasHeaderCloseButton: true,
            hasActionBar: true,
            contactIds: contactIds,
            eaOid,
          },
        });

        this.closeSidebar();
      });
  }

  onCreateCallingListClicked(): void {
    this.showCreateCallingListSidebar = true;
  }

  handleSendPotentialBuyersToERP(): void {
    this.pbService.selectedPotentialBuyers$
      .pipe(withLatestFrom(this.eaOid$), first())
      .subscribe(([potentialBuyers, eaOid]) => {
        this.sendPotentialBuyerService.sendPotentialBuyersToERP({
          eaOid,
          potentialBuyers,
        });
      });

    this.sendPotentialBuyerService
      .getIsDone()
      .pipe(
        filter((isDone) => isDone),
        first(),
        withLatestFrom(
          this.sendPotentialBuyerService.getErrors(),
          this.eaOid$,
          this.hasExternalPotentialBuyerState$
        )
      )
      .subscribe(([_isDone, errors, eaOid, hasExternalPotentialBuyerState]) => {
        if (hasExternalPotentialBuyerState) {
          this.pbService.getPotentialBuyersStatusByObject(eaOid).subscribe();
        }
        if (errors?.length === 0) {
          this.closeSidebar();
        }
      });
  }

  private mapStateToProps(): void {
    this.potentialBuyers$ = this.pbService.selectedPotentialBuyers$.pipe(
      takeUntil(this.unsubscribe$),
      map((contacts) => contacts.map((c) => c.contactId)),
      tap((contactIds) => this.callingListContactIds$.next(contactIds)),
      switchMap((contactIds) =>
        this.contactService.currentList$.pipe(
          map((contactList) =>
            contactList.filter((c) => contactIds.includes(c.contactId))
          ),
          takeUntil(this.unsubscribe$),
          distinctUntilChanged()
        )
      )
    );
    this.eaOid$ = this.store.pipe(select(fromShowings.getShowingObjectEaOid));
    this.object$ = this.eaOid$.pipe(
      switchMap((eaOid) =>
        this.objectService.entityMap$.pipe(
          map((em) => em[eaOid]),
          filter((it) => !!it)
        )
      ),
      takeUntil(this.unsubscribe$)
    );
    this.externalProviderName$ = this.store.pipe(
      select(getFeature(EXTERNAL_PROVIDER)),
      map((feature: ExternalProviderFeature) => feature.name)
    );
    this.showCreatePrivateShowing$ = this.store.pipe(
      select(getFeature(CREATE_PRIVATE_SHOWING)),
      map((feature) => feature.enabled)
    );
    this.mobilePhoneIsRequired$ = this.store.pipe(
      select(fromConfig.getFeature(POTENTIAL_BUYERS)),
      map(
        (potentialBuyersFeature: PotentialBuyersFeature) =>
          potentialBuyersFeature.mobilePhoneIsRequired
      )
    );
    this.potentialBuyersWithoutPhone$ = combineLatest([
      this.potentialBuyers$,
      this.mobilePhoneIsRequired$,
    ]).pipe(
      map(([potentialBuyers, mobilePhoneIsRequired]) => {
        if (!mobilePhoneIsRequired || potentialBuyers?.length === 0) {
          return [];
        }

        return potentialBuyers.filter(
          (potentialBuyer) =>
            !potentialBuyer.msisdn && !potentialBuyer.phoneNumber
        );
      })
    );
    this.hasExternalPotentialBuyerState$ = this.store.pipe(
      select(hasIntegration(IntegrationResource.GetExternalPotentialBuyerState))
    );
    this.checkConsentAlarmLevelEnable$ = this.store.pipe(
      select(getFeature(CONSENT_ALARM_LEVEL)),
      map((feature) => feature.enabled)
    );
  }

  openSendMessageSidebar() {
    this.pbService.selectedPotentialBuyers$
      .pipe(
        withLatestFrom(this.eaOid$, this.checkConsentAlarmLevelEnable$),
        first()
      )
      .subscribe(([buyers, eaOid, checkConsent]) => {
        this.closeSidebar();
        const users = buyers.map(
          (buyer) => new ContactablePotentialBuyer(buyer)
        );
        const actions = [
          SendMessageActions.setTemplateGroup({
            templateGroupName: TemplateGroups.Showings,
          }),
          SendMessageActions.setExtraDataSet({ extraDataSet: { eaOid } }),
          SendMessageActions.addContacts({ recipients: users }),
          SendMessageActions.setCheckConsent({ checkConsent }),
          SendMessageActions.setEaOid({ eaOid }),
        ];
        this.store.dispatch(
          RouterActions.go({
            path: ["/crm/", { outlets: { sidebar: SIDEBAR_SEND_MESSAGE_URL } }],
          })
        );
        actions.forEach((action) => this.store.dispatch(action));
      });
  }

  handleBiddingStatusChange(bidStatus: string): void {
    this.isWorking$.next(true);
    combineLatest([this.potentialBuyers$, this.eaOid$])
      .pipe(first())
      .subscribe(([potentialBuyers, eaOid]) => {
        const requests = potentialBuyers.map((pb) =>
          this.contactService.postContactObjectConnection(pb.contactId, {
            eaOid,
            bidStatus,
          })
        );
        const taskSet: TaskSet = {
          label: "update_bidding",
          tasks: requests,
        };

        this.store.dispatch(addTaskSetToQueue(taskSet));
        this.isWorking$.next(false);
        this.closeSidebar();
      });
  }

  closeSidebar() {
    this.store.dispatch(closeTab({ tabType: this.tabType }));
  }
}
