<div class="table-wrapper">
  <div class="header">
    <h3 translate>nps_latest_answers</h3>
  </div>
  <div class="table-body-container" [class.extra-padding]="hasExtraPadding">
    <div *ngIf="loading; else dataTable" class="loading">
      <spinner [inlineBlock]="true" [size]="'1.2rem'"></spinner>
    </div>
    <ng-template #dataTable>
      <table class="table table--striped">
        <tbody>
          <ng-container *ngFor="let result of list">
            <tr (click)="openNewTab(result)">
              <td>
                <div class="cell-container">
                  <div class="name-span">
                    <div class="cell-divider">{{ result.responseDate }}</div>
                    <span
                      class="truncate"
                      [title]="
                        result.contactFirstName + ' ' + result.contactFamilyName
                      "
                    >
                      {{ result.contactFirstName }}
                      {{ result.contactFamilyName }}
                    </span>
                    <span>{{ result.nps }}</span>
                  </div>
                  <span [class]="getColor(result.NPSClass)">
                    <ng-container *ngIf="getIcon(result.NPSClass) as icon">
                      <app-icon
                        [name]="icon"
                        type="solid"
                        size="small"
                      ></app-icon>
                    </ng-container>
                  </span>
                </div>
              </td>
            </tr>
          </ng-container>
          <tr
            *ngIf="!list || list?.length === 0"
            style="cursor: default !important"
          >
            <td colspan="3" class="list-empty" translate>
              no_data_available_for_list
            </td>
          </tr>
        </tbody>
      </table>
    </ng-template>
  </div>
</div>
