import { Injectable } from "@angular/core";
import { ApiService, Categories } from "@app/core/services/api/api.service";
import { ExternalObjectMetadata } from "@app/integrations/bidding/models/external-object-metadata";
import { IntegrationResource } from "@app/integrations/models/enums";
import { ExternalSystemLinks } from "@app/integrations/models/ErpSystemLinks";
import { Integration } from "@app/integrations/models/Integration";
import { IntegrationCopyObject } from "@app/integrations/models/IntegrationCopyObject";
import { IntegrationProviders } from "@app/integrations/models/IntegrationProviders";
import { IntegrationSupportedObjectData } from "@app/integrations/models/IntegrationSupportedObjectData";
import { StoreIntegrationCopyObject } from "@app/integrations/models/StoreIntegrationCopyObject";
import { SupportedLinkType } from "@app/integrations/models/SupportedLinkType";
import * as integrationActions from "@app/integrations/ngrx/integrations.actions";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of as observableOf, switchMap } from "rxjs";
import { PropertyTrigger } from "@app/integrations/models/propertyTrigger";

@Injectable()
export class IntegrationEffects {
  fetchIntegrations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(integrationActions.fetchIntegrationsRequest),
      switchMap(() =>
        this.api.get("", {}, Categories.Integrations).pipe(
          mergeMap((integration: Integration) => {
            const actions = this.fetchAvailableIntegrations(integration);
            return actions;
          }),
          catchError(() =>
            observableOf(integrationActions.fetchIntegrationsFailure())
          )
        )
      )
    )
  );

  private fetchAvailableIntegrations(integration: Integration) {
    const actions = [];
    actions.push(integrationActions.fetchIntegrationsSuccess({ integration }));
    actions.push(
      integrationActions.fetchIntegrationContactSearchCapabilitiesRequest()
    );
    actions.push(integrationActions.fetchNewsletterIsConfiguredRequest());
    actions.push(integrationActions.fetchBrandkeeperIsConfiguredRequest());
    actions.push(integrationActions.fetchAdfenixPropertyTriggerConfigRequest());

    if (integration.hasIntegration) {
      actions.push(integrationActions.fetchIntegrationProviderRequest());
    }

    if (integration?.integratedResources.includes(IntegrationResource.Object)) {
      actions.push(
        integrationActions.fetchIntegrationSupportedObjectDataRequest()
      );
    }

    if (
      integration?.integratedResources.includes(
        IntegrationResource.ErpSystemLinks
      )
    ) {
      actions.push(
        integrationActions.fetchIntegrationErpSystemLinksUrlRequest()
      );
    }

    if (
      integration?.integratedResources.includes(
        IntegrationResource.LinkGeneration
      )
    ) {
      actions.push(
        integrationActions.fetchIntegrationSupportedLinkTypesRequest()
      );
    }

    return actions;
  }

  fetchIntegrationProvider$ = createEffect(() =>
    this.actions$.pipe(
      ofType(integrationActions.fetchIntegrationProviderRequest),
      switchMap(() =>
        this.api.get("providers", {}, Categories.Integrations).pipe(
          map((providers: IntegrationProviders) =>
            integrationActions.fetchIntegrationProviderSuccess({ providers })
          ),
          catchError(() =>
            observableOf(integrationActions.fetchIntegrationProviderFailure())
          )
        )
      )
    )
  );

  fetchExternalTipsIntegrationDescriptionRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(integrationActions.fetchExternalTipsIntegrationDescriptionRequest),
      switchMap(() =>
        this.api
          .get(
            "external-tips/integration-description",
            {},
            Categories.Integrations
          )
          .pipe(
            map((response: { externalTipsSupported: boolean }) =>
              integrationActions.fetchExternalTipsIntegrationDescriptionSuccess(
                { externalTipsSupported: response.externalTipsSupported }
              )
            ),
            catchError(() =>
              observableOf(
                integrationActions.fetchExternalTipsIntegrationDescriptionFailure()
              )
            )
          )
      )
    )
  );

  fetchExternalTipsIntegrationDescriptionSupport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(integrationActions.fetchExternalTipsIntegrationDescriptionSuccess),
      map(({ externalTipsSupported }) =>
        externalTipsSupported
          ? integrationActions.fetchIntegrationParentReceiverIsConfiguredForOfficeRequest()
          : integrationActions.fetchIntegrationParentReceiverIsConfiguredForOfficeFailure()
      )
    )
  );

  fetchIntegrationParentReceiver$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        integrationActions.fetchIntegrationParentReceiverIsConfiguredForOfficeRequest
      ),
      switchMap(() =>
        this.api
          .get(
            "external-tips/parent-receivers-is-configured-for-office",
            {},
            Categories.Integrations
          )
          .pipe(
            map((response: { officeIsConfigured: boolean }) =>
              integrationActions.fetchIntegrationParentReceiverIsConfiguredForOfficeSuccess(
                { officeIsConfigured: response.officeIsConfigured }
              )
            ),
            catchError(() =>
              observableOf(
                integrationActions.fetchIntegrationParentReceiverIsConfiguredForOfficeFailure()
              )
            )
          )
      )
    )
  );

  fetchIntegrationSupportedLinkTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(integrationActions.fetchIntegrationSupportedLinkTypesRequest),
      switchMap(() =>
        this.api
          .get(`generate-url/supported/types`, {}, Categories.Integrations)
          .pipe(
            map((supportedLinkTypes: SupportedLinkType[]) =>
              integrationActions.fetchIntegrationSupportedLinkTypesSuccess({
                supportedLinkTypes,
              })
            ),
            catchError(() =>
              observableOf(
                integrationActions.fetchIntegrationSupportedLinkTypesFailure()
              )
            )
          )
      )
    )
  );

  fetchIntegrationSupportedObjectData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(integrationActions.fetchIntegrationSupportedObjectDataRequest),
      switchMap(() =>
        this.api
          .get("integrated-object-supported-data", {}, Categories.Integrations)
          .pipe(
            map((res: IntegrationSupportedObjectData) =>
              integrationActions.fetchIntegrationSupportedObjectDataSuccess({
                supportedObjectData: res.supportedObjectData,
              })
            ),
            catchError(() =>
              observableOf(
                integrationActions.fetchIntegrationSupportedObjectDataFailure()
              )
            )
          )
      )
    )
  );

  fetchIntegrationEngineAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(integrationActions.fetchIntegrationEngineAdminUrlRequest),
      switchMap(() =>
        this.api
          .get(
            "admin/integration-engine-admin-url",
            {},
            Categories.Integrations
          )
          .pipe(
            map((res: { url: string }) =>
              integrationActions.fetchIntegrationEngineAdminUrlSuccess({
                integrationEngineAdminUrl: res.url,
              })
            ),
            catchError(() =>
              observableOf(
                integrationActions.fetchIntegrationEngineAdminUrlFailure()
              )
            )
          )
      )
    )
  );

  fetchIsNewsletterConfigured$ = createEffect(() =>
    this.actions$.pipe(
      ofType(integrationActions.fetchNewsletterIsConfiguredRequest),
      switchMap(() =>
        this.api
          .get("newsletter/is-configured", {}, Categories.Integrations)
          .pipe(
            map((response: { isConfigured: boolean }) =>
              integrationActions.fetchNewsletterIsConfiguredSuccess({
                newsletterIsConfigured: response.isConfigured,
              })
            ),
            catchError(() =>
              observableOf(
                integrationActions.fetchNewsletterIsConfiguredFailure()
              )
            )
          )
      )
    )
  );

  fetchIntegrationContactSearchCapabilities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        integrationActions.fetchIntegrationContactSearchCapabilitiesRequest
      ),
      switchMap(() =>
        this.api
          .get("contact-search/capabilities", {}, Categories.Integrations)
          .pipe(
            map((contactSearchCapabilities: string[]) =>
              integrationActions.fetchIntegrationContactSearchCapabilitiesSuccess(
                { contactSearchCapabilities }
              )
            ),
            catchError(() =>
              observableOf(
                integrationActions.fetchIntegrationContactSearchCapabilitiesFailure()
              )
            )
          )
      )
    )
  );

  fetchIntegrationCopyObject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(integrationActions.fetchIntegrationCopyObjectOptionsRequest),
      switchMap(({ eaOid }) =>
        this.api.get(`object-copy/${eaOid}`, {}, Categories.Integrations).pipe(
          map((objectCopyOptions: IntegrationCopyObject) =>
            integrationActions.fetchIntegrationCopyObjectOptionsSuccess({
              objectCopyOptions,
            })
          ),
          catchError(() =>
            observableOf(
              integrationActions.fetchIntegrationCopyObjectOptionsFailure()
            )
          )
        )
      )
    )
  );

  storeIntegrationCopyProperty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(integrationActions.storeIntegrationCopyObjectRequest),
      switchMap(({ parameters: { eaOid, params } }) => {
        return this.api
          .post(`object-copy/${eaOid}/copy`, params, Categories.Integrations)
          .pipe(
            map((storedCopyObjects: StoreIntegrationCopyObject) =>
              integrationActions.storeIntegrationCopyObjectSuccess({
                storedCopyObjects,
              })
            ),
            catchError(() =>
              observableOf(
                integrationActions.storeIntegrationCopyObjectFailure()
              )
            )
          );
      })
    )
  );

  fetchIsBrandkeeperConfigured$ = createEffect(() =>
    this.actions$.pipe(
      ofType(integrationActions.fetchBrandkeeperIsConfiguredRequest),
      switchMap(() =>
        this.api
          .get("brandkeeper/is-configured", {}, Categories.Integrations)
          .pipe(
            map((response: { isConfigured: boolean }) =>
              integrationActions.fetchBrandkeeperIsConfiguredSuccess({
                brandkeeperIsConfigured: response.isConfigured,
              })
            ),
            catchError(() =>
              observableOf(
                integrationActions.fetchBrandkeeperIsConfiguredFailure()
              )
            )
          )
      )
    )
  );

  fetchBrandkeeperUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(integrationActions.fetchBrandkeeperUrlRequest),
      switchMap(() =>
        this.api
          .get("brandkeeper/generate-iframe-url", {}, Categories.Integrations)
          .pipe(
            map((res: { url: string }) =>
              integrationActions.fetchBrandkeeperUrlSuccess({
                brandkeeperUrl: res.url,
              })
            ),
            catchError(() =>
              observableOf(integrationActions.fetchBrandkeeperUrlFailure())
            )
          )
      )
    )
  );

  fetchIntegrationErpSystemLinks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(integrationActions.fetchIntegrationErpSystemLinksUrlRequest),
      switchMap(() =>
        this.api.get("erp-system-links", {}, Categories.Integrations).pipe(
          map((erpSystemLinks: ExternalSystemLinks) =>
            integrationActions.fetchIntegrationErpSystemLinksUrlSuccess({
              erpSystemLinks,
            })
          ),
          catchError(() =>
            observableOf(
              integrationActions.fetchIntegrationErpSystemLinksUrlFailure()
            )
          )
        )
      )
    )
  );

  fetchExternalObjectMetadata$ = createEffect(() =>
    this.actions$.pipe(
      ofType(integrationActions.fetchExternalObjectMetadataRequest),
      switchMap(({ eaOid }) =>
        this.api
          .get(
            `external-meta-data/object/${eaOid}`,
            {},
            Categories.Integrations
          )
          .pipe(
            map((externalObjectMetadata: ExternalObjectMetadata) =>
              integrationActions.fetchExternalObjectMetadataSuccess({
                externalObjectMetadata,
              })
            ),
            catchError(() =>
              observableOf(
                integrationActions.fetchExternalObjectMetadataFailure()
              )
            )
          )
      )
    )
  );

  fetchIntegrationEraIntranetUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(integrationActions.fetchIntegrationEraIntranetUrlRequest),
      switchMap(() =>
        this.api.get("era/intranet/url", {}, Categories.Integrations).pipe(
          map((res: { url: string }) =>
            integrationActions.fetchIntegrationEraIntranetUrlSuccess({
              eraIntranetUrl: res.url,
            })
          ),
          catchError(() =>
            observableOf(
              integrationActions.fetchIntegrationEraIntranetUrlFailure()
            )
          )
        )
      )
    )
  );

  fetchAdfenixPropertyTriggerConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(integrationActions.fetchAdfenixPropertyTriggerConfigRequest),
      switchMap(() =>
        this.api
          .get(
            "adfenix/property/triggers/is-configured",
            {},
            Categories.Integrations
          )
          .pipe(
            mergeMap((res: { isConfigured: boolean }) => {
              const actions = [];

              actions.push(
                integrationActions.fetchAdfenixPropertyTriggerConfigSuccess({
                  adfenixPropertyTriggerIsConfigured: res.isConfigured,
                })
              );

              if (res.isConfigured) {
                actions.push(
                  integrationActions.fetchAdfenixPropertyTriggersRequest()
                );
              }

              return actions;
            }),
            catchError(() =>
              observableOf(
                integrationActions.fetchAdfenixPropertyTriggerConfigFailure()
              )
            )
          )
      )
    )
  );

  fetchAdfenixPropertyTriggers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(integrationActions.fetchAdfenixPropertyTriggersRequest),
      switchMap(() =>
        this.api
          .get("adfenix/property/triggers", {}, Categories.Integrations)
          .pipe(
            map((res: { triggers: PropertyTrigger[] }) =>
              integrationActions.fetchAdfenixPropertyTriggersSuccess({
                triggers: res.triggers,
              })
            ),
            catchError(() =>
              observableOf(
                integrationActions.fetchAdfenixPropertyTriggersFailure()
              )
            )
          )
      )
    )
  );

  fetchInternalService$ = createEffect(() =>
    this.actions$.pipe(
      ofType(integrationActions.fetchInternalServiceRequest),
      switchMap(({ serviceName }) =>
        this.api
          .get(
            `internal-services/is-enabled/${serviceName}`,
            {},
            Categories.Integrations
          )
          .pipe(
            map((res: { enabled: boolean }) =>
              integrationActions.fetchInternalServiceSuccess({
                serviceName,
                enabled: res.enabled,
              })
            ),
            catchError(() =>
              observableOf(
                integrationActions.fetchInternalServiceFailure({ serviceName })
              )
            )
          )
      )
    )
  );

  constructor(private actions$: Actions, private api: ApiService) {}
}
