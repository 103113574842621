<section>
  <div>
    <img src="./assets/img/realforce_data.svg" alt="Realforce data platform" />
    <div>
      <p translate>welcome</p>
      <div class="loading-spinner center-screen">
        <spinner [size]="'5rem'"></spinner>
      </div>
    </div>
  </div>
</section>
